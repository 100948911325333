import * as React from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { BrowserRouter, Routes,Route, Link } from "react-router-dom";
import Navigator from "./pages/navigator";
import Content from "./pages/content";
import Report from "./pages/reports";
import Support from "./pages/support";
import Header from "./pages/header";
import Login from "./pages/login";
import useToken from './useToken';
import config from "./config/config";
import SupportSetting from "./pages/supportSetting";
import UserDetail from "./pages/userDetail";
import Media from "./pages/media";
import NewUser from "./pages/createNewUser";
import Policy from './pages/policy';
import Terms_adn_condition from './pages/terms_adn_condition';
import Community_guidelines from './pages/community_guidelines';
import PhotoVerificationPage from "./pages/photoVerification";
import PhotoVerificationSettingPage from "./pages/photoVerificationSetting";
import FaceVerificationPage from "./pages/faceVerify";
import FaceVerificationSettingPage from "./pages/faceVerifSetting";
import DateQuestionPage from "./pages/date_question"
import CovidQuestionPage from "./pages/covid_question";
import AccountDeletionRequestPage from "./pages/account_deletion_requests";
import AccountDeletionRequestsSettingPage from "./pages/accountDeletionRequestsSetting";


let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function Paperbase() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {token,setToken} = useToken();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const supportTabs = window.location.pathname == '/support' ? config.SUPPORT_TABS_LIST :
      (window.location.pathname == '/supportSetting' ? config.SUPPORT_TABS_SETTING :
          (window.location.pathname == '/photoVerification' ? config.PHOTO_VERIFICATION_TABS_LIST :
              (window.location.pathname == '/photoVerificationSetting' ? config.PHOTO_VERIFICATION_TABS_SETTING :
                  (window.location.pathname == '/faceVerify' ? config.FACE_VERIFY_TABS_LIST :
                      (window.location.pathname == '/faceVerifySetting' ? config.FACE_VERIFY_TABS_SETTING :
                          (window.location.pathname == '/accountsupport' ? config.ACCOUNT_DELETION_REQUESTS_TABS_LIST :
                              (window.location.pathname == '/accountsupportSetting' ? config.ACCOUNT_DELETION_REQUESTS_TABS_SETTING :
                                      []
                              )))))))
  const supportTabsValue = window.location.pathname == '/support' ? 0 :
      (window.location.pathname == '/supportSetting' ? 1 :
          (window.location.pathname =='/photoVerification' ? 0 :
              (window.location.pathname == '/photoVerificationSetting' ? 1 :
                  (window.location.pathname == '/faceVerify' ? 0 :
                      (window.location.pathname == '/faceVerifySetting' ? 1 :
                      (window.location.pathname == '/accountsupport' ? 0 :
                          (window.location.pathname == '/accountsupportSetting' ? 1 : 0
                          )))))))
  if(window.location.pathname == '/policy'){
    return ( <Policy/>)
  }else if(window.location.pathname == '/terms_and_conditions') {
    return ( <Terms_adn_condition/>)
  }
  else if(window.location.pathname == '/community_guidelines'){
    return ( <Community_guidelines/>)
  }else{
    if(!token){
      return <Login setToken={setToken} />
    }else{
      return (
          <ThemeProvider theme={theme}>
            <Box sx={{ display: "flex", minHeight: "100vh" }}>
              <CssBaseline />
              <Box
                  component="nav"
                  sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              >
                {isSmUp ? null : (
                    <Navigator
                        PaperProps={{ style: { width: drawerWidth } }}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    />
                )}

                <Navigator
                    PaperProps={{ style: { width: drawerWidth } }}
                    sx={{ display: { sm: "block", xs: "none" } }}
                />
              </Box>
              <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Header onDrawerToggle={handleDrawerToggle} tabs={supportTabs} tabs_value={supportTabsValue}/>
                <Box
                    component="main"
                    sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}
                >
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<Content />}/>
                      <Route path='/reports' element={<Report/>} />
                      <Route path='/support' element={<Support/>} />
                      <Route path='/supportSetting' element={<SupportSetting/>} />
                      <Route path ='/userDetail/:id' element={<UserDetail/>} />
                      <Route path='/media' element={<Media/>} />
                      <Route path='/photoVerification' element={<PhotoVerificationPage/>} />
                      <Route path='/photoVerificationSetting' element={<PhotoVerificationSettingPage/>} />
                      <Route path='/faceVerify' element={<FaceVerificationPage/>} />
                      <Route path='/faceVerifySetting' element={<FaceVerificationSettingPage/>} />
                      <Route path='/create_user' element={<NewUser/>} />
                      <Route path='/date_question' element={<DateQuestionPage/>} />
                      <Route path='/covid_question' element={<CovidQuestionPage/>} />
                      <Route path='/accountsupport' element={<AccountDeletionRequestPage/>} />
                      <Route path='/accountsupportSetting' element={<AccountDeletionRequestsSettingPage/>} />
                    </Routes>
                  </BrowserRouter>

                  {/*<Content />*/}
                </Box>
                <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
                  {/* <Copyright /> */}
                </Box>
              </Box>
            </Box>
          </ThemeProvider>
      );
    }
  }
}


