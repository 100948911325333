import * as React from "react";
import Paper from "@mui/material/Paper";
import {UserDetailPage} from "../API/api-requst";
import {
    useParams
} from "react-router-dom";
export default function UserDetail() {
    let { id } = useParams();
    return (
        <Paper sx={{ maxWidth: "auto", margin: "auto", overflow: "hidden"  }}>
            <UserDetailPage id={id}/>
        </Paper>
    );
}
