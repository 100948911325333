import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useCategories from "../useNavigation";
import LogoutIcon from '@mui/icons-material/Logout';
import '../css/navigation.css'

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other} = props;
  const {categories,setCategories} = useCategories();
  const changeNavItem = async e =>{
    let id = e.target.innerText
    setCategories(id)
  }
  const logOutNav = async e =>{
    window.location.pathname = '/'
    localStorage.removeItem("token");
  }
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          Roja Rose
        </ListItem>
        {/*<ListItem sx={{ ...item, ...itemCategory }}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <HomeIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText>Project Overview</ListItemText>*/}
        {/*</ListItem>*/}
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#101F33" }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active,href }) => (
              <ListItem disablePadding key={childId} onClick={changeNavItem}>
                <a className='navigation-link' href={href}>
                  <ListItemButton selected={active} sx={item} >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </a>
              </ListItem>
            ))}

            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
        <ListItem sx={{fontSize: 20, color: "#fff" }}>
          <ListItemButton onClick={logOutNav}>
            <ListItemText>Log Out</ListItemText>
            <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
// Navigator.propTypes = {
//   setCategories: PropTypes.func.isRequired
// }
