import * as React from "react";
import Paper from "@mui/material/Paper";
import {MessageList} from "../API/api-requst";

export default function Report() {
    return (
        <Paper sx={{ maxWidth: "auto", margin: "auto", overflow: "hidden" }}>
            <MessageList/>
         </Paper>
    );
}
