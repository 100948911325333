import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../css/Login.css';
import { loginUser } from "../API/api-requst";


 export default function  Login({setToken}) {
     const [invalidMessage, setInvalidMessage] = useState();
     let state ={}

     class FluidInput extends React.Component {
         constructor(props) {
             super(props);
             this.state = {
                 focused: false,
                 value: ""
             };
         }
         focusField() {
             const { focused } = this.state;
             this.setState({
                 focused: !focused
             });
         }
         handleChange(event) {
             const { target } = event;
             const { value,id } = target;
             this.setState({
                 value: value
             });
             if(id == 'phone'){
                 state.phone = value
             }else if(id == 'password'){
                 state.password = value
             }
         }
         render() {
             const { type, label, style, id } = this.props;
             const { focused, value } = this.state;

             let inputClass = "fluid-input";
             if (focused) {
                 inputClass += " fluid-input--focus";
             } else if (value != "") {
                 inputClass += " fluid-input--open";
             }

             return (
                 <div className={inputClass} style={style}>
                     <div className="fluid-input-holder">

                         <input
                             className="fluid-input-input"
                             type={type}
                             id={id}
                             onFocus={this.focusField.bind(this)}
                             onBlur={this.focusField.bind(this)}
                             onChange={this.handleChange.bind(this)}
                             value={this.state.value}
                             autoComplete="off"
                             // defaultValue={this.state.value}
                         />
                         <label className="fluid-input-label" >{label}</label>

                     </div>
                 </div>
             );
         }
     }

     class Button extends React.Component {
         render() {
             return (
                 <div className={`button ${this.props.buttonClass}`} onClick={this.props.onClick}>
                     {this.props.buttonText}
                 </div>
             );
         }
     }
     const handleSubmit = async e => {
         e.preventDefault();
         if(state.phone && state.password){
             state.phone = state.phone.replace(/[^+\d]/g, '')
             const token = await loginUser({
                 phone : state.phone,
                 password : state.password
             });
             if(token && !token.message){
                 setInvalidMessage()
                 setToken(token.data);
             }else{
                 setInvalidMessage(token.message)
             }
         }

     }

     const style = {
         margin: "15px 0"
     };
     return (
         <form>
             <div className="login-container">
                 <div className="image-container">
                 </div>
                 <div className="invalid-login-message">{invalidMessage}</div>
                     <div className="title">
                         Login
                     </div>
                     <FluidInput  type="text" label="name" id="phone" style={style}  />
                     <FluidInput type="password" label="password" autoComplete="new-password" id="password" style={style} />
                     <Button buttonText="log in" type="submit" buttonClass="login-button" onClick={handleSubmit} />
             </div>
         </form>

     );
};
Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
