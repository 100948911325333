import React,{Component} from "react";
import '../css/popup.css'

export default class PopupSupport extends Component{
    constructor(props) {
        super(props);
        this.state={
        }
    }
    render() {
        return(
            <div className={this.props.active ? "modal active":"modal"} onClick={() =>{this.props.setActive(false)}}>
                <div className={this.props.active ? "modal__content active":"modal__content"} onClick={e =>e.stopPropagation()}>
                    <div>
                        <div className="report-users">
                            <div className="created-user-content">
                                <div className="block-title">
                                    <p>Detail</p>
                                </div>
                                <div className="user-info-block">
                                    <div className="user-photo">
                                        <img src={this.props.reportData.userFrom.profilePhoto ? this.props.reportData.userFrom.profilePhoto :"https://kca.gov.kg/images/placeHolder.png" } className="created-user-icon"></img>
                                    </div>
                                    <div className="user-detail-block">
                                        <h3>
                                            Name: {this.props.reportData.userFrom.name}<br></br>
                                            Phone: {this.props.reportData.userFrom.phone}<br></br>
                                            Uid: {this.props.reportData.userFrom.uid}<br></br>
                                            Email: {this.props.reportData.userFrom.email}<br></br>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="report-info-block">
                            <div className="report-info-type-title">
                                <p>Type: {this.props.reportData.type}</p>
                            </div>
                            <div className="report-info-text">
                                <h2>Question:</h2>
                                <p className="report-text-message">{this.props.reportData.question}</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}


