import React,{Component} from "react";
import '../css/popup.css'

export default class PopupConfirm extends Component{
    constructor(props) {
        super(props);
        this.state={
        }
    }
    render() {
        return(
            <div className={this.props.active ? "modal active":"modal"} onClick={() =>{this.props.setActive(false)}}>
                <div className={this.props.active ? "modal__content active":"modal__content"} onClick={e =>e.stopPropagation()}>
                    <p>{this.props.text}</p>
                </div>
            </div>
        )
    }
}


