import { useState } from 'react';
import PeopleIcon from "@mui/icons-material/People";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import PermMediaOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActual";
import PublicIcon from "@mui/icons-material/Public";
import ReportIcon from '@mui/icons-material/Report';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import MasksIcon from '@mui/icons-material/Masks';
import * as React from "react";

const default_categories = [
    {
        id: "Build",
        children: [
            {
                id: "Users",
                icon: <PeopleIcon />,
                active: true,
                href:'/'
            },
            { id: "Account Support", icon: <DnsRoundedIcon />,href:'/accountsupport' },
            { id: "Photo Verification", icon: <PermMediaOutlinedIcon />,href:'/photoVerification' },
            { id: "Face Verification", icon: <ContactMailIcon />,href:'/faceVerify' },
            { id: "Media", icon: <PermMediaOutlinedIcon />,href:'/media' },
            { id: "Support messages (Account/Payments etc)", icon: <PublicIcon />,href:'/support' },
            { id: "Reports", icon: <ReportIcon />,href:'/reports' },
            { id: "Date Questionnaire", icon: <DynamicFormIcon />,href:'/date_question'},
            { id: "Covid Questionary", icon: <MasksIcon />,href:'/covid_question'},

        ],
    },

]

export default function useCategories() {
    const getCategories = () => {
        let pathName = window.location.pathname
        let navigation
        if(pathName){
            pathName = pathName == '/supportSetting' ? '/support' : (pathName == '/faceVerifySetting' ? '/faceVerify': (pathName == '/photoVerificationSetting' ? '/photoVerification' : pathName))
            default_categories.map(i =>{
                i.children.map(child =>{
                    if(pathName == child.href){
                        navigation = child.id
                    }
                })
            })
        }
        if(navigation && navigation != 'undefined'){
            const userCategories = navigation ? navigation : JSON.parse(sessionStorage.getItem('navigation'));
            return userCategories ? default_categories.map(i => {
                i.children.map(child => {
                    if(userCategories && child.id == userCategories){
                        child.active = true
                    }else{
                        child.active = false
                    }
                    return child
                })
                return i
            }) :  default_categories
        }else{
            return default_categories
        }
    };

    const [categories, setCategories] = useState(getCategories());

    const saveCategories = userCategories => {
        sessionStorage.setItem('navigation', JSON.stringify(userCategories));
        setCategories(userCategories);
    };

    return {
        setCategories: saveCategories,
        categories
    }
}
