import * as React from "react";
import '../css/policy.css';

export default function Support() {
    return (
        <div>
            <div className='policy_back'></div>
                <div className='policy_main'>
                        <h1>Our commitment to privacy</h1>
                        <p>We design all of our products and services with your privacy in mind. We involve experts from various fields, including legal, security, engineering, product design and others to make sure that our decisions are taken with the utmost respect for your privacy.</p>

                        <h3>Our commitment to transparency</h3>
                        <p>Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. We take the exact opposite approach: we’re doing our best to write our Privacy Policy and related documents in plain language. We actually want you to read our policies and understand our privacy practices!</p>

                        <h3>Our commitment to security</h3>
                        <p>We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.</p>

                        <h1>Privacy Policy</h1>
                        <p>Welcome to our Privacy Policy. Thank you for taking the time to read it.</p>
                        <p>If you are a California resident, please see our California Privacy Statement, which supplements this Privacy Policy.</p>
                        <p>We appreciate that you trust us with your information, and we intend to always keep that trust. This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.</p>
                        <p>This Privacy Policy applies from 24 February 2023. The previous version of this Privacy Policy will apply until then.</p>

                        <h2>1.Who we are</h2>
                        <p>Roja Rose</p>
                        <p>233 Cresthaven Dr.</p>
                        <p>Nepean, ON</p>
                        <p>K2G 6X1</p>
                        <p>CAN</p>

                        <h2>2. Where This Privacy Policy Applies</h2>
                        <p>This Privacy Policy applies to websites, apps, events and other services we operate under the Roja Rose brand. For simplicity, we refer to all of these as our “services” in this Privacy Policy. To make it extra clear, we’ve added links to this Privacy Policy on all applicable services.</p>
                        <p>Some services may require their own unique privacy policy. If a service has its own privacy policy, then that policy – not this Privacy Policy – applies.</p>

                        <h2>3. Information We Collect</h2>
                        <p>It goes without saying that we can’t help you develop meaningful connections without some information about you, such as basic profile details and the types of people you’d like to meet. We also collect information about your use of our services such as access logs, as well as information from third parties, like when you access our services through your social media account or when you upload information from your social media account to complete your profile. If you want additional info, we go into more detail below.</p>

                        <h3>Information you give us</h3>
                        <p>You choose to give us certain information when using our services. This includes:</p>
                        <ul className="ul-polity-block">
                                <li>When you create an account, you provide us with at least your phone number and email address, as well as some basic details necessary for the service to work, such as your gender, date of birth and who you’d like to connect with.</li>
                                <li>When you complete your profile, you have the option to share your sexual orientation with us. Where you provide such elements to us, you consent to us using it for the purposes identified and as laid out in this Privacy Policy. You can also share additional information with us, such as details on your bio and your interests, as well as content such as photos and videos. To add certain content, like pictures or videos, you may allow us to access your camera or photo album.</li>
                                <li>When you subscribe to a paid service or make a purchase directly from us (rather than through a platform such as iOS or Android), you provide us with information related to the purchases you make and our payment processors with information such as your debit or credit card number or other financial information.</li>
                                <li>When you participate in surveys, focus groups or market studies, you give us your insights into our products and services, responses to our questions and testimonials.</li>
                                <li>When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.</li>
                                <li>If you contact our customer care team, we collect the information you give us during the interaction.</li>
                                <li>If you share with us information about other people (for example, if you use contact details of a friend for a given feature), we process this information on your behalf, in order to complete your request.</li>
                                <li>Of course, we also process your chats with other members, as well as the content you publish, to operate and secure the services, and to keep our community safe.</li>
                        </ul>

                        <h3>Information we receive from others</h3>
                        <p>In addition to the information you may provide us directly, we receive information about you from others, including:</p>
                        <ul  className="ul-polity-block">
                                <li><strong>Members:</strong> Members may provide information about you as they use our services, for instance as they interact with you or if they submit a report involving you.</li>
                                <li><strong>Social media:</strong> You may decide to share information with us through your social media account, for instance if you decide to create and log in to your Roja Rose account via your social media or other account (e.g. Facebook, Google or Apple), or to upload onto our services information such as photos from one of your social media accounts (e.g. Instagram or Spotify).</li>
                                <li><strong>Affiliates:</strong> We are part of the Match Group family of businesses. Match Group considers the safety and security of its members a top priority. If you were banned from another Match Group service, your information can be shared with us to allow us to take necessary actions, including closing your account or preventing you from creating an account on our services.</li>
                                <li><strong>Other partners:</strong> We may receive information about you from our partners where our ads are published on a partner’s service (in which case they may pass along details on a campaign’s success). Where legally allowed, we can also receive information about suspected or convicted bad actors from third parties, as part of our efforts to ensure our members’ safety and security.</li>
                        </ul>
                        <h1>Information generated or automatically collected when you use our services</h1>
                        <p>When you use our services, this generates technical data about which features you’ve used, how you’ve used them and the devices you use to access our services. See below for more details.</p>
                        <h2>Usage information</h2>
                        <p>Using the services generates data about your activity on our services, for instance how you use them (e.g. when you logged in, features you’ve been using, actions taken, information shown to you, referring webpages address and ads that you interacted with) and your interactions with other members (e.g. members you connect and interact with, and when you matched and exchanged messages with them).</p>
                        <h2>Device information</h2>
                        <p>We collect information from and about the device(s) you use to access our services, including hardware and software information such as IP address, device ID and type, app settings and characteristics, app crashes, advertising IDs (which are randomly generated numbers that you can reset by going into your device settings and, in some cases, disable entirely), and identifiers associated with cookies or other technologies that may uniquely identify a device or browser.</p>
                        <h2>Information collected by cookies and similar technologies</h2>
                        <p>We use and may allow others to use cookies and similar technologies (e.g. web beacons, pixels, SDKs) to recognise you and/or your device(s). You may read our Cookie Policy for more information on why we use them and how you can better control their use.</p>
                        <p>Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a 'Do Not Track' ('DNT') feature which tells a website that a user does not want to have their online activity tracked. If a website that responds to a DNT signal receives a DNT signal, the browser can block that website from collecting certain information about the browser’s user. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason, many businesses, including ours, do not currently respond to DNT signals.</p>
                        <h2>Other information with your consent:</h2>
                        <h3>Precise geolocation data</h3>
                        <p>If you give us your consent, we can collect your precise geolocation (latitude and longitude) from your device. The collection of your geolocation may occur in the background even when you aren’t using the services if the permission you gave us expressly permits such collection. If you decline permission for us to collect your precise geolocation, we will not collect it, and those of our services which rely on precise geolocation may not be available to you.</p>
                        <h3>Photo verification data</h3>
                        <p>If you choose to participate in our photo verification feature, we collect your face geometry data, which may be considered biometric data in some jurisdictions, to verify that you’re the real you. You can learn more about photo verification and how we process your face geometry data.</p>
                        <h3>Other information</h3>
                        <p>We may collect other information with your permission, such as photos and videos (for instance, if you want to publish a photo or video or participate in streaming features on our services).</p>

                        <h2>4. How We Use Information</h2>
                        <p>The main reason we use your information is to deliver and improve our services. Additionally, we use your info to help keep you and our community safe, and to provide you with advertising that may be of interest to you. Read on for a more detailed explanation of the various reasons for which we use your information, together with practical examples.</p>

                        <h3>A. To administer your account and provide our services to you</h3>
                        <ul  className="ul-polity-block">
                                <li>Create and manage your account</li>
                                <li>Provide you with customer support and respond to your requests</li>
                                <li>Communicate with you about our services</li>
                                <li>Personalise pricing, offer discounts and other promotions, and complete your transactions</li>
                                <li>Administer sweepstakes and contests</li>
                        </ul>

                        <h3>B. To help you connect with other users</h3>
                        <ul  className="ul-polity-block">
                                <li>Recommend you to other members and recommend other members to you</li>
                                <li>Show members’ profiles to one another</li>
                                <li>Enable members to search for and connect with other members</li>
                        </ul>

                        <h3>C. To provide new Roja Rose services to you</h3>
                        <ul  className="ul-polity-block">
                                <li>Register you and display your profile on new Roja Rose features and apps</li>
                                <li>Administer your account on these new features and apps</li>
                        </ul>

                        <h3>D. To provide offers and operate advertising and marketing campaigns</h3>
                        <ul  className="ul-polity-block">
                                <li>Perform and measure the effectiveness of advertising campaigns on our services and marketing our services off our platform</li>
                                <li>Communicate with you about products or services that we believe may interest you</li>
                        </ul>

                        <h3>E. To improve our services and develop new ones</h3>
                        <ul  className="ul-polity-block">
                                <li>Administer focus groups, market studies and surveys</li>
                                <li>Review interactions with customer care teams to improve our quality of service</li>
                                <li>Understand how members typically use the services to improve them (for instance, we may decide to change the look and feel or even substantially modify a given feature based on how members react to it)</li>
                                <li>Develop new features and services (for example, we may decide to build a new interests-based feature further to requests received from members)</li>
                        </ul>

                        <h3>F. To prevent, detect and fight fraud and other illegal or unauthorised activities</h3>
                        <ul  className="ul-polity-block">
                                <li>Find and address ongoing, suspected or alleged violations of our Terms of Use, notably through the review of reports and interactions between members</li>
                                <li>Better understand and design countermeasures against violations of our Terms of Use</li>
                                <li>Retain data related to violations of our Terms of Use to address the violation and prevent against recurrences</li>
                                <li>Enforce or exercise our rights, for example our rights set out in our Terms of Use</li>
                                <li>Communicate to individuals who submit a report, including what we’ve done as a result of their submission</li>
                        </ul>

                        <h1>G. To ensure legal compliance</h1>
                        <ul  className="ul-polity-block">
                                <li>Comply with legal requirements</li>
                                <li>Assist law enforcement</li>
                        </ul>

                        <p>For information on how we process personal information through profiling and automated decision-making, please see our FAQ.</p>

                        <h2>Legal Bases for Processing Personal Information</h2>
                        <p>To process your information as described in this Privacy Policy, we rely on the following legal bases:</p>
                        <ol className="ul-polity-block">
                                <li>Provide our service to you: The reason we process your information for purposes A, B and C above is to perform the contract that you have with us. For instance, as you go about using our service to build meaningful connections, we use your information to maintain your account and your profile, make it viewable to other members and recommend other members to you, and to otherwise provide our free and paid features to you and other members.</li>
                                <li>Legitimate interests: We process your information for purposes D, E and F above, based on our legitimate interest. For instance, we analyse users’ behaviour on our services to continuously improve our offerings, we suggest offers we think might interest you and promote our own services, we process information to help keep our members safe, and we process data where necessary to enforce our rights, assist law enforcement and enable us to defend ourselves in the event of a legal action.</li>
                                <li>Comply with applicable laws and regulations: We process your information for purpose G above where it is necessary for us to comply with applicable laws and regulations and evidence our compliance with applicable laws and regulations. For example, we retain traffic data and data about transactions in line with our accounting, tax and other statutory data retention obligations and to be able to respond to valid access requests from law enforcement. We also keep data evidencing consents members give us and decisions they may have taken to opt out of a given feature or processing.</li>
                                <li>Consent: If you choose to provide us with information that may be considered 'special' or 'sensitive' in certain jurisdictions, such as your sexual orientation, you’re consenting to our processing of that information in accordance with this Privacy Policy. From time to time, we may ask for your consent to collect specific information such as your precise geolocation or use your information for certain specific reasons. In some cases, you may withdraw your consent by adapting your settings (for instance in relation to the collection of our precise geolocation) or by deleting your content (for instance where you entered information in your profile that may be considered 'special' or 'sensitive'). In any case, you may withdraw your consent at any time by contacting us at the address provided at the end of this Privacy Policy.</li>
                        </ol>

                        <h2>5.How We Share Information</h2>
                        <p>Since our goal is to help you make meaningful connections, the main sharing of members’ information is, of course, with other members. We also share some types of member information with service providers and partners who assist us in operating the services, with other Match Group companies for specified reasons as laid out below and, in some cases, legal authorities. Read on for more details about how your information is shared with others.</p>
                        <h3>With other members</h3>
                        <p>You share information with other members when you voluntarily disclose information on the service (including your public profile). Please be careful with your information, and make sure that the content you share are things you’re comfortable with being visible. If you choose to limit the audience for all or part of your profile, or for certain content or information about you, then it will be visible according to your settings. If someone submits a report involving you (such as a claim you violated our Terms of Use), we may communicate to the reporter actions, if any, we took as a result of their report.</p>
                        <h3>With our service providers and partners</h3>
                        <p>We use vendors to help us operate, distribute, market and improve our services, such as data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing and security operations. We also share information with vendors who distribute and assist us in advertising our services. For instance, we may share limited information on you in hashed, non-human readable form to advertising vendors. We follow a strict vetting process prior to engaging any vendor or working with any partner. Our vendors and partners must agree to strict confidentiality obligations.</p>
                        <h3>With our affiliates</h3>
                        <p>We are part of the Match Group family of businesses. We share your information with affiliates for limited legitimate purposes as laid out below:</p>
                        <ul  className="ul-polity-block">
                                <li>To make all Match Group platforms safer and enable us to address (e.g. ban) bad actors found on one platform also on the others</li>
                                <li>For them to assist us in data processing operations, as processors/service providers, upon our instructions and on our behalf. Their assistance may include technical processing operations, such as data hosting and maintenance, customer care, marketing and targeted advertising, analytics, finance and accounting assistance, improving our service, securing our data and systems and fighting against spam, abuse, fraud, infringement and other wrongdoings</li>
                                <li>We may also share information with other Match Group companies for other legitimate business purposes including corporate audit, analysis and consolidated reporting, where and as allowed under applicable law</li>
                        </ul>
                        <h3>Sharing functionality</h3>
                        <p>You may choose to share other members’ profiles and they may share yours with people outside of our services, using the sharing functionality.</p>
                        <h3>For corporate transactions</h3>
                        <p>We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganisation, dissolution, bankruptcy or other change of ownership or control.</p>
                        <h3>With law enforcement/when required by law</h3>
                        <p>We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court order, subpoena or search warrant, government/law enforcement investigation or other legal requirements; (ii) to assist in the prevention or detection of crime (subject in each case to applicable law); or (iii) to protect the safety of any person.</p>

                        <h3>To enforce legal rights</h3>
                        <p>We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and the legal rights of our members, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent or take other action regarding illegal activity, suspected fraud or other wrongdoing.</p>

                        <h3>With your consent or at your request</h3>
                        <p>We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.</p>
                        <p>We may use and share non-personal information (meaning information that, by itself, does not identify who you are such as device information, general demographics, general behavioural data, location in de-identified form), as well as personal information in hashed, non-human readable form, under any of the above circumstances. We may also share this information with other Match Group companies and third parties (notably advertisers) to develop and deliver targeted advertising on our services and on websites or applications of third parties, and to analyse and report on advertising you see. We may combine this information with additional non-personal information or personal information in hashed, non-human readable form collected from other sources. More information on our use of cookies and similar technologies can be found in our Cookie Policy</p>

                        <h3>6. Cross-border data transfers</h3>
                        <p> Sharing of information laid out in Section 5 involves cross-border data transfers to the United States of America and other jurisdictions that may have different laws about data processing. When we transfer personal information outside of the EEA, the United Kingdom, Switzerland or other countries which data protection laws have been deemed adequate by the European Commission or other competent governmental body, we use standard contract clauses (standard contractual clauses are commitments between companies transferring personal data, binding them to protect the privacy and security of your data) or other appropriate transfer mechanism. We are currently in the process of reviewing transfers to our vendors and associated legal basis further to the recent Court of Justice for the European Union’s ruling on transfers of personal data to the USA.</p>

                        <h3>7.Your rights and choices</h3>
                        <p>We want you to be in control of your information, so we want to remind you of the following options and tools available to you:</p>
                        <ul  className="ul-polity-block">
                                <li>
                                        Access/update tools in the service. Tools and account settings can help you access, rectify or remove information that you provided to us and that’s associated with your account directly within the service. If you have any questions on those tools and settings, please contact our customer care team for help.
                                </li>
                                <li>
                                        Device permissions. Mobile platforms can have permission systems for specific types of device data and notifications, such as phone contacts, pictures, location services, push notifications and advertising identifiers. You can change your settings on your device to either consent or oppose the collection or processing of the corresponding information or the display of the corresponding notifications. Of course, if you do that, certain services may lose functionality.
                                </li>
                                <li>
                                        Uninstall. You can stop all information collection by an app by uninstalling it using the standard uninstall process for your device. Remember that uninstalling an app does NOT close your account. To close your account, please use the corresponding functionality on the service.
                                </li>
                                <li>
                                        Account closure. You can close your account by using the corresponding functionality directly on the service.
                                </li>
                        </ul>
                        <p>We also want you to be aware of your privacy rights. Depending on where you live, you may have the right to:</p>
                        <ul  className="ul-polity-block">
                                <li>
                                        Access/know. You may have the right to request a copy of the information we keep about you, and in certain circumstances to receive this in a portable format. You can exercise your right to access directly within the service by putting in a request.
                                </li>
                                <li>
                                        Delete/erase. You may request that we delete the personal information we keep about you. You can exercise your right to delete by submitting a request.
                                </li>
                                <li>
                                        Correct/rectify/update. You can correct most information you provided to us by editing your profile directly in the service. If you believe the information we hold about you is inaccurate, you may contact us to rectify it.
                                </li>
                                <li>
                                        Object/restrict. You may also have the right to object to or request that we restrict certain processing. To do so, please contact us.
                                </li>
                        </ul>
                        <p>For your protection and the protection of all of our members, we may ask you to provide proof of identity before we can answer the above requests. Keep in mind, we may reject requests, including if we are unable to authenticate you, if the request is unlawful or invalid, or if it may infringe on trade secrets or intellectual property or the privacy or other rights of someone else. If you wish to receive information relating to another member, such as a copy of any messages you received from them through our service, the other member will have to contact us to provide their written consent before the information is released. We may also ask them to provide proof of identity before we can answer the request. We may also not be able to accommodate certain requests to object to or restrict the processing of personal information, notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your date of birth and, thus, cannot ensure that you are 18 years of age or older.</p>

                        <p>If you are a resident of Virginia, USA, if we deny your privacy request, you may be able to appeal by contacting us, and explicitly referencing 'Privacy Request Appeal'. If you have concerns about the result of your appeal, you may contact the attorney general for your state.</p>

                        <p>In certain countries, including in the European Economic Area and the United Kingdom, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how we process your personal information. You can find information about your data protection regulator in the European Economic Area here, and in the United Kingdom here. The data protection authority you can lodge a complaint with may be that of your habitual residence, where you work or where an alleged infringement took place.</p>

                        <h3>8.How long we retain your information</h3>

                        <p>We keep your personal information only as long as we need it for legitimate business purposes (as laid out in Section 4) and as permitted by applicable law. If you decide to stop using our services, you can close your account and your profile will stop being visible to other members. Note that we will close your account automatically if you are inactive for a period of two years. After your account is closed, we will delete your personal information, as laid out below:</p>

                        <p>1.To protect the safety and security of our members, we implement a safety retention window of three months following account closure, or one year following an account ban. During this period, we keep your information in the event that it might be necessary to investigate unlawful or harmful conducts. The retention of information during this safety retention window is based on our legitimate interest, as well as that of potential third-party victims.</p>
                        <p>2.Once the safety retention window elapses, we delete your data and only keep limited information for specified purposes, as laid out below:</p>
                        <ul  className="ul-polity-block">
                                <li><p><strong>a) Legal data retention obligations:</strong> We maintain limited data to comply with legal data retention obligations, such as keeping transaction data for 10 years to comply with tax and accounting legal requirements, credit card information for the duration the user may challenge the transaction, and "traffic data"/logs for one year to comply with legal data retention obligations. We also keep records of consents members give us for five years to evidence our compliance with applicable law.</p></li>
                                <li><p><strong>b) Legitimate interest:</strong> We maintain limited information on the basis of our legitimate interest, such as keeping customer care records and supporting data as well as imprecise location of download/purchase for five years to support our customer care decisions, enforce our rights and enable us to defend ourselves in the event of a claim, information on the existence of past accounts and subscriptions, which we delete three years after the closure of your last account to ensure proper and accurate financial forecasting and reporting, profile data for one year in anticipation of potential litigation, for the establishment, exercise or defence of legal claims, and data necessary to prevent members who were banned from opening a new account, for as long as necessary to ensure the safety and vital interests of our members.</p></li>
                                <li><p><strong>c) Outstanding or potential issue, claim or dispute:</strong> Finally, we maintain information on the basis of our legitimate interest where there is an outstanding or potential issue, claim or dispute requiring us to keep information (in particular if we receive a valid legal subpoena or request asking us to preserve data, or if data would otherwise be necessary as part of legal proceedings).</p></li>
                        </ul>

                        <h2>9.No children allowed</h2>
                        <p>Our services are restricted to individuals who are 18 years of age or older. We do not permit individuals under the age of 18 on our platform. If you suspect that a member is under the age of 18, please use the reporting mechanism available on the service.</p>

                        <h2>10.Job candidates, contractors and vendor representatives</h2>
                        <p>
                                We process the personal information of our job candidates, contractors and vendor representatives, as part of our recruitment and talent management operations and our management of the services that contractors and vendors provide to us. If you are a job candidate, contractor or vendor representative of ours, certain relevant terms of this Privacy Policy apply to our processing of your personal information, including the sections of this Privacy Policy that discuss the entity that is responsible for the processing of your personal information, transfers of personal information, rights you may have under applicable law, how to contact us and California-specific information.
                        </p>
                        <p>
                                If you are a job applicant, the personal information we process about you may vary depending on the job you seek, but, typically, includes what you provide to us as part of your job application, as well as professional qualifications, background and reference information that recruiters or other third parties share with us. We use this information to support the recruitment process, which may lead to an employment contract.
                        </p>
                        <p>
                                For contractors and vendor representatives, we may process identification information and work-related information, as necessary to manage our relationship with you and your employer, which is necessary for the performance of the services agreement, and to establish, exercise or defend potential legal claims. We may share personal information with service providers that assist us with recruitment and technical data processing operations, as well as with Match Group companies (for instance, if you have a business relationship with employees of an affiliate). We keep your personal information only as long as is necessary for those purposes.
                        </p>
                        <h2>11.Privacy Policy changes</h2>
                        <p>As we constantly seek to find new and innovative ways to help you establish meaningful connections, we may update this Privacy Policy from time to time. We aim to ensure that any explanation about our data practices stays current and accurate.</p>
                        <p>If any material changes are made to this policy, we will notify you in advance so that you have sufficient time to review and understand the changes.</p>
                </div>
        </div>
);
}







