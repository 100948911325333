import * as React from "react";
import Paper from "@mui/material/Paper";
import {SupportSettingPage} from "../API/api-requst";

export default function SupportSetting() {
    return (
        <Paper sx={{ maxWidth: "auto", margin: "auto", overflow: "hidden" }}>
            <SupportSettingPage/>
        </Paper>
    );
}
