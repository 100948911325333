import axios from "axios";
import React from 'react';
import * as config from "../config/config";
import { DataGrid} from '@mui/x-data-grid';
import { Modal,ButtonGroup} from '@mui/material';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import 'react-data-grid/lib/styles.css';
import '../css/Users.css'
import '../css/DataQeustion.css';
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Popup from "../components/popup"
import PopupSupport from "../components/popupSupport";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import PopupConfirm from "../components/popup_confirm";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
//LOGIN
const userToken = localStorage.getItem('token')
const parseToken = JSON.parse(userToken)
if(parseToken && parseToken.token){
    axios.defaults.headers.post['Authorization'] = `Bearer ${parseToken.token}`;
    axios.defaults.headers.get['Authorization'] = `Bearer ${parseToken.token}`;
}
export const loginUser = async function (credentials) {

    let result = await axios({
        method: 'post',
        url: `${config.SERVER_URL}admin/users/login`,
        data: {
            phone:credentials.phone,
            password:credentials.password
        },
    }).then((response) => {
        axios.defaults.headers.get['Authorization'] = `Bearer ${response.data.data?.token}`
        axios.defaults.headers.post['Authorization'] = `Bearer ${response.data.data?.token}`;
        return response.data
    }, (error) => {
    });
    return result
}


//DATE QUESTION//
export class DateQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            open_modal:false,
        };
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getQuestionConfig/data_question`,
        }).then((response) => {
            let questions = response.data
            this.setState({questions})
            if(response.data && response.data.rows && !response.data.rows.length){
                axios({
                    method: 'get',
                    url: `${config.SERVER_URL}admin/users/getQuestionConfig/data_question`,
                }).then((response) => {
                    let questions = response.data
                    this.setState({questions})
                })
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    addQuestion() {
        this.setState({
            questions: [...this.state.questions, { question: '', answers: [{text:'',number:1},{text:'',number:2},{text:'',number:3},{text:'',number:4},{text:'',number:5}] }],
        });
    }

    updateQuestionText(index, text) {
        const updatedQuestions = [...this.state.questions];
        updatedQuestions[index].question = text;
        this.setState({ questions: updatedQuestions });
    }
    updateQuestionCheckbox(index,type) {
        const updatedQuestions = [...this.state.questions];
        if(type == 'isHumorous'){
            updatedQuestions[index].isHumorous = !updatedQuestions[index].isHumorous

        }else{
            updatedQuestions[index].isShortlisted = !updatedQuestions[index].isShortlisted

        }
        this.setState({ questions: updatedQuestions });
    }

    // addAnswer(index, event, answer) {
    //     const updatedQuestions = [...this.state.questions];
    //     updatedQuestions[index].answers = [...updatedQuestions[index].answers, answer];
    //     this.setState({ questions: updatedQuestions });
    // }

    deleteQuestion(index) {
        const updatedQuestions = [...this.state.questions];
        updatedQuestions.splice(index, 1);
        this.setState({ questions: updatedQuestions });
    }

    // deleteAnswer(questionIndex, answerIndex) {
    //     const updatedQuestions = [...this.state.questions];
    //     updatedQuestions[questionIndex].answers.splice(answerIndex, 1);
    //     this.setState({ questions: updatedQuestions });
    // }

    saveQuestion() {
        const updatedQuestions = [...this.state.questions];
        let isValid = true;
        updatedQuestions.forEach((question) => {
            if (!question.question || question.question === '') {
                question.isQuestionInvalid = true;
                isValid = false;
            } else {
                question.isQuestionInvalid = false;
            }
            let newMassAnswer = []
            question.answers.forEach((answer) =>{
                let newAnswer
                if(typeof answer == 'string'){
                    if (!answer || answer === '') {
                        newAnswer ={
                            text : answer,
                            isAnswerInvalid :true,
                            number: answer.number
                        }
                        newMassAnswer.push(newAnswer)
                        isValid = false;
                    } else {
                        newAnswer ={
                            text : answer,
                            isAnswerInvalid :false,
                            number: answer.number
                        }
                        newMassAnswer.push(newAnswer)
                    }
                }else{
                    if (!answer.text || answer.text === '') {
                        newAnswer ={
                            text : answer.text,
                            isAnswerInvalid :true,
                            number: answer.number
                        }
                        newMassAnswer.push(newAnswer)
                        isValid = false;
                    } else {
                        newAnswer ={
                            text : answer.text,
                            isAnswerInvalid :false,
                            number: answer.number
                        }
                        newMassAnswer.push(newAnswer)
                    }
                }
            })

        });
        if (isValid) {
            this.setState({ questions: updatedQuestions });
            this.setState({open_modal:true})

            axios({
                method: 'post',
                url: `${config.SERVER_URL}admin/users/saveQuestion`,
                data:{type:'data_question', questions:this.state.questions}
            }).then((response) => {
            }, (error) => {
                if(error && error.response && error.response.data){
                    if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                        localStorage.removeItem("token");
                        window.location.pathname ='/'
                    }
                }
            });
        } else {
            this.setState({ questions: updatedQuestions });
        }
    }
    CloseButton = () => {
        return (
            <svg
                onClick={(e)=>{
                    this.setState({open_modal:false})
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
            >
                <path
                    fill="none"
                    d="M0 0h24v24H0z"
                />
                <path
                    fill="#000000"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
            </svg>
        );
    };
    render() {
        return (
            <div className="data-question-container">
                {this.state.questions.map((question, index) => (
                    <div className="data-question-container-row" key={index}>
                        <div className="data-question-header">
                            <input
                                type="text"
                                value={question.question}
                                placeholder="Question title"
                                onChange={(e) => this.updateQuestionText(index, e.target.value)}
                                className={question.isQuestionInvalid ? "data-question-input invalid" : "data-question-input"}
                            />
                            <button
                                onClick={() => this.deleteQuestion(index)}
                                className="data-question-delete-button"
                            >
                            </button>
                        </div>
                        <div className="data-question-answer-container">
                            <div className="data-question-answer-title-checkbox">
                                <span>Іs shortlisted:</span>
                                <input
                                    className='list-item-checkbox'
                                    type='checkbox'
                                    checked={question.isShortlisted}
                                    onChange={this.updateQuestionCheckbox.bind(this,index,'isShortlisted')}
                                />
                            </div>
                            <div className="data-question-answer-title-checkbox">
                                <span>Іs humorous:</span>
                                <input
                                    className='list-item-checkbox'
                                    type='checkbox'
                                    checked={question.isHumorous}
                                    onChange={this.updateQuestionCheckbox.bind(this,index,'isHumorous')}
                                />
                            </div>
                            {question.answers.length ? (
                                <div className="data-question-answer-title">Answers:</div>
                            ) : (
                                ''
                            )}

                            {question.answers.map((answer, answerIndex) => (
                                <div key={answerIndex} className="data-question-answer-item">
                                    {answerIndex + 1}.{' '}
                                    <input
                                        className={answer && answer.isAnswerInvalid ? "data-question-input invalid" : "data-question-input"}
                                        type="text"
                                        placeholder="Answer"
                                        defaultValue={(answer && answer.text || answer && answer.text =='') ? answer.text : answer}
                                        onChange={(e) => {
                                            const updatedQuestions = [...this.state.questions];
                                            updatedQuestions[index].answers[answerIndex] = {text:e.target.value, number:answer.number};
                                            this.setState({ questions: updatedQuestions });
                                        }}
                                    />
                                    {/*<button*/}
                                    {/*    onClick={() => this.deleteAnswer(index, answerIndex)}*/}
                                    {/*    className="data-question-delete-button"*/}
                                    {/*>*/}
                                    {/*</button>*/}
                                </div>
                            ))}
                        </div>
                        {/*<div className="data-question-footer">*/}
                        {/*    <input*/}
                        {/*        className="data-question-input"*/}
                        {/*        type="text"*/}
                        {/*        placeholder="Add answer"*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter' && e.target.value) {*/}
                        {/*                this.addAnswer(index, e, e.target.value);*/}
                        {/*                e.target.value = '';*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                ))}
                <button onClick={this.addQuestion.bind(this)} className="data-question-add-question-button">
                    Add question
                </button>
                {this.state.questions && this.state.questions.length ? (
                    <button onClick={this.saveQuestion.bind(this)} className="data-question-save-question">
                        Save
                    </button>
                ) : (
                    ''
                )}
                <Modal open={this.state.open_modal} onClose={() => this.setState({open_modal:false})}>
                    <div className='decline_modal_div'>
                        <div className='decline_model_content'>
                            <div className='successfully-modal-title'> Saved successfully</div>
                            <div className='div-successfully-modal-icon'>
                                <CheckCircleOutlineIcon sx={{ color: 'green', width:'200px', height:'200px' }} />
                            </div>
                            <div className='close_modal_btn'><this.CloseButton/></div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

//COVID QUESTION//
export class CovidQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            open_modal:false,
        }
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getQuestionConfig/covid_question`,
        }).then((response) => {
            let questions = response.data
            this.setState({questions})
            if(response.data && response.data.rows && !response.data.rows.length){
                axios({
                    method: 'get',
                    url: `${config.SERVER_URL}admin/users/getQuestionConfig/covid_question`,
                }).then((response) => {
                    let questions = response.data
                    this.setState({questions})
                })
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    addQuestion() {
        this.setState({ questions: [...this.state.questions, { question: ''}] });
    }

    updateQuestionText(index, text) {
        const updatedQuestions = [...this.state.questions];
        updatedQuestions[index].question = text;
        this.setState({ questions: updatedQuestions });
    }
    saveQuestion() {
        const updatedQuestions = [...this.state.questions];
        let isValid = true;
        updatedQuestions.forEach((question) => {
            if (!question.question || question.question === '') {
                question.isQuestionInvalid = true;
                isValid = false;
            } else {
                question.isQuestionInvalid = false;
            }
        });
        if (isValid) {
            this.setState({ questions: updatedQuestions });
            this.setState({open_modal:true})
            axios({
                method: 'post',
                url: `${config.SERVER_URL}admin/users/saveQuestion`,
                data:{type:'covid_question', questions:this.state.questions}
            }).then((response) => {
            }, (error) => {
                if(error && error.response && error.response.data){
                    if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                        localStorage.removeItem("token");
                        window.location.pathname ='/'
                    }
                }
            });
        } else {
            this.setState({ questions: updatedQuestions });
        }
    }
    CloseButton = () => {
        return (
            <svg
                onClick={(e)=>{
                    this.setState({open_modal:false})
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
            >
                <path
                    fill="none"
                    d="M0 0h24v24H0z"
                />
                <path
                    fill="#000000"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
            </svg>
        );
    };
    render() {
        return (
            <div className='data-question-container'>
                {this.state.questions.map((question, index) => (
                    <div className="data-question-container-row" key={index}>
                        <input
                            type="text"
                            value={question.question}
                            placeholder="Question title"
                            onChange={(e) => this.updateQuestionText(index, e.target.value)}
                            className={question.isQuestionInvalid ? "data-question-input invalid" : "data-question-input"}
                        />
                    </div>
                ))}
                <button onClick={this.addQuestion.bind(this)} className="data-question-add-question-button">Add question</button>
                {this.state.questions && this.state.questions.length ? (
                    <button onClick={this.saveQuestion.bind(this)} className="data-question-save-question">
                        Save
                    </button>
                ) : (
                    ''
                )}
                <Modal open={this.state.open_modal} onClose={() => this.setState({open_modal:false})}>
                    <div className='decline_modal_div'>
                        <div className='decline_model_content'>
                            <div className='successfully-modal-title'> Saved successfully</div>
                            <div className='div-successfully-modal-icon'>
                                <CheckCircleOutlineIcon sx={{ color: 'green', width:'200px', height:'200px' }} />
                            </div>
                            <div className='close_modal_btn'><this.CloseButton/></div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

//FACE VERIFICATION
export class FaceVerification extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            users : [],
            page : 1,
            perPage:10,
            count_page: 1,
            full_screen_photo:'',
            open_modal:false,
        }
    }
    columns  =[
        {field:'agoraUId',headerName:'Agora UID',  width: 200},
        {field:'first_name',headerName:'First Name',width: 150},
        {field:'middle_name',headerName:'Middle Name',width: 150},
        {field:'last_name',headerName:'Last Name',width: 150},
        {
            field:'photos',
            headerName:"Photo",
            width: 150,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const handleImgClick = (e) => {
                    this.setState({full_screen_photo:e.target.src});
                    this.setState({open_modal:true});
                };
                return (
                    <div>
                        {params.value && params.value.length ? params.value.map((photo) => (
                            <img className='photo-verification-table-img' key={photo.id} src={photo.link} onClick={handleImgClick}  />
                        )) : ''}
                    </div>
                )
            }
        },
        {
            field:'example',
            headerName:"Example",
            width: 150,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const handleImgClick = (e) => {
                    this.setState({full_screen_photo:e.target.src});
                    this.setState({open_modal:true});
                };
                return (
                    <div>
                        {params.value ?
                            <img className='photo-verification-table-img' key={params.value} src={params.value} onClick={handleImgClick}  />
                         : ''}
                    </div>
                )
            }
        },
        {
            field:'photoVerification',
            headerName:"Verification Status",
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        {params.value ? (
                            <div className='photo-verification-span-yes'>YES</div>
                        ) :  <div className='photo-verification-span-yes no'>NO</div>}
                    </div>
                )
            }
        },
        {
            field:'_id',
            headerName:"Buttons",
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const handleBtnClick = (e) => {
                    const id = e.target.getAttribute('data_id')
                    const attribute = e.target.getAttribute('data_attribute')
                    let users = this.state.users
                        this.setState({decline_modal:false})
                        users = users.map(e =>{
                            if(e._id == id){
                                e.photoVerification = attribute && attribute == 'true' ? true : false
                                if(e.photoVerification == false){
                                    e.photos =[]
                                }
                            }
                            return  e
                        })
                        this.setState({users})
                        axios({
                            method: 'post',
                            url: `${config.SERVER_URL}admin/users/updateUserVerify`,
                            data:{id,photoVerification:attribute,face_id:params.row.face_id}
                        }).then((response) => {
                            console.log('true')
                        })
                };
                return (
                    <div className="button-container">
                        <button className="green-button" data_id={params.value} data_attribute='true' onClick={handleBtnClick}>approve</button>
                        <button className="red-button"  data_id={params.value} data_attribute='false' onClick={handleBtnClick}>decline</button>
                    </div>
                )
            }
        },

    ]
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getFaceVerification`,
        }).then((response) => {
            response.data = response.data.data
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({users})
            if(response.data && response.data.rows && !response.data.rows.length){
                axios({
                    method: 'post',
                    url: `${config.SERVER_URL}admin/users/getFaceVerification`,
                    data:{current_page:this.state.page,items_per_page:this.state.perPage}
                }).then((response) => {
                    response.data = response.data.data
                    const users = response.data.rows
                    const pages = response.data.pages
                    this.setState({count_page: pages})
                    this.setState({users})
                })
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    paginationChange(event,value){
        const page = value
        this.setState({page:parseInt(page)})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/getFaceVerification`,
            data:{current_page:page,items_per_page:this.state.perPage,filter:this.state.filter.id}
        }).then((response) => {
            response.data = response.data.data
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({users})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }

    checkPagination(countPage){
        if(countPage > 1){
            return(
                <Pagination  count={this.state.count_page} page={this.state.page} component="div" onChange={this.paginationChange.bind(this)}/>
            )
        }
    }
    CloseButton = () => {
        return (
            <svg
                onClick={(e)=>{
                    this.setState({decline_modal:false})
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
            >
                <path
                    fill="none"
                    d="M0 0h24v24H0z"
                />
                <path
                    fill="#000000"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
            </svg>
        );
    };
    getRowId = (row) => row._id;
    render() {
        return(
            <div>
                <Box sx={{ height: 650, width: '100%'}}>
                    <DataGrid
                        columns={this.columns}
                        rows={this.state.users}
                        getRowId={this.getRowId}
                        rowHeight={100}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                    />
                </Box>
                {this.checkPagination(this.state.count_page)}
                <Modal open={this.state.open_modal} onClose={() => this.setState({open_modal:false})}>
                    <img
                        src={this.state.full_screen_photo}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </Modal>
            </div>
        )
    }
}
//Account Deletion Request
export class AccountDeletionRequest extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            users : [],
            page : 1,
            perPage:10,
            count_page: 1,
            filter:{
                id:'All'
            },
            filter_count:{
                all:0,
                approve:0,
                no_approve:0,
            }
        }
    }
    columns  =[
        {field:'agoraUId',headerName:'Agora UID',  width: 200},
        {field:'first_name',headerName:'First Name',width: 150},
        {field:'middle_name',headerName:'Middle Name',width: 150},
        {field:'last_name',headerName:'Last Name',width: 150},
        {
            field:'approve',
            headerName:"Approve",
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        {params.value ? (
                            <div className='photo-verification-span-yes'>YES</div>
                        ) :  <div className='photo-verification-span-yes no'>NO</div>}
                    </div>
                )
            }
        },
        {
            field:'_id',
            headerName:"Buttons",
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const handleBtnClick = (e) => {
                    const id = e.target.getAttribute('data_id')
                    const attribute = e.target.getAttribute('data_attribute')
                    let users = this.state.users
                    users = users.map(e =>{
                        if(e._id == id){
                            e.approve = attribute && attribute == 'true' ? true : false
                            if(e.approve == false){
                            }
                        }
                        return  e
                    })
                    this.setState({users})
                    axios({
                        method: 'post',
                        url: `${config.SERVER_URL}admin/users/updateUserAccountDeletion`,
                        data:{id,approve:attribute}
                    }).then((response) => {
                        if(attribute == 'false'){
                            this.paginationChange(this.state.page)
                        }
                    })
                };
                return (
                    <div className="button-container">
                        <button className="green-button" data_id={params.value} data_attribute='true' onClick={handleBtnClick}>approve</button>
                        <button className="red-button"  data_id={params.value} data_attribute='false' onClick={handleBtnClick}>decline</button>
                    </div>
                )
            }
        },
        {
            field:"user_id",
            headerName:"Detail",
            width:200,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <Link to={`/userDetail/${params.value}`} className='support-setting-button' target="_blank">
                            User Detail
                        </Link>
                    </div>
                )
            }
        }

    ]
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/userAccountDeletionRequest`,
        }).then((response) => {
            response.data = response.data.data
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({users})
            if(response.data.allCount){
                this.setState({filter_count:{
                        all:response.data.allCount.all,
                        approve:response.data.allCount.approve,
                        no_approve:response.data.allCount.no_approve,
                    }})
            }
            if(response.data && response.data.rows && !response.data.rows.length){
                axios({
                    method: 'post',
                    url: `${config.SERVER_URL}admin/users/userAccountDeletionRequest`,
                    data:{current_page:this.state.page,items_per_page:this.state.perPage}
                }).then((response) => {
                    response.data = response.data.data
                    const users = response.data.rows
                    const pages = response.data.pages
                    this.setState({count_page: pages})
                    this.setState({users})
                    if(response.data.allCount){
                        this.setState({filter_count:{
                                all:response.data.allCount.all,
                                approve:response.data.allCount.approve,
                                no_approve:response.data.allCount.no_approve,
                            }})
                    }
                })
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    paginationChange(event,value){
        const page = value
        this.setState({page:parseInt(page)})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/userAccountDeletionRequest`,
            data:{current_page:page,items_per_page:this.state.perPage,filter:this.state.filter.id}
        }).then((response) => {
            response.data = response.data.data
            const users = response.data.rows
            const pages = response.data.pages
            if(response.data.allCount){
                this.setState({filter_count:{
                        all:response.data.allCount.all,
                        approve:response.data.allCount.approve,
                        no_approve:response.data.allCount.no_approve,
                    }})
            }
            this.setState({count_page:pages})
            this.setState({users})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }

    checkPagination(countPage){
        if(countPage > 1){
            return(
                <Pagination  count={this.state.count_page} page={this.state.page} component="div" onChange={this.paginationChange.bind(this)}/>
            )
        }
    }
    clickButtonFilter = async (event)=>{
        let filter = event.target.id
        this.setState({filter:{id:event.target.id}})
        this.setState({page:1})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/userAccountDeletionRequest`,
            data:{current_page:1,items_per_page:this.state.perPage,filter:filter}
        }).then((response) => {
            response.data = response.data.data
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({users})
            if(response.data.allCount){
                this.setState({filter_count:{
                        all:response.data.allCount.all,
                        approve:response.data.allCount.approve,
                        no_approve:response.data.allCount.no_approve,
                    }})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }

    getRowId = (row) => row._id;
    render() {
        return(
            <div>
                <div className="report-buttons-type">
                    <ButtonGroup color="primary" aria-label="outlined primary button group" size="large" onClick={this.clickButtonFilter.bind(this)}>
                        <Button  id="All" className={this.state.filter.id && this.state.filter.id == 'All'?"filter-reports-button active" : "filter-reports-button"} data-active="true">All<br></br>{this.state.filter_count.all} </Button>
                        <Button id="Approve" className={this.state.filter.id && this.state.filter.id == 'Approve'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Approve<br></br>{this.state.filter_count.approve}</Button>
                        <Button id="No Approve" className={this.state.filter.id && this.state.filter.id == 'No Approve'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">No Approve<br></br>{this.state.filter_count.no_approve}</Button>
                    </ButtonGroup>
                </div>
                <Box sx={{ height: 650, width: '100%'}}>
                    <DataGrid
                        columns={this.columns}
                        rows={this.state.users}
                        getRowId={this.getRowId}
                        rowHeight={100}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                    />
                </Box>
                {this.checkPagination(this.state.count_page)}
            </div>
        )
    }
}
//PHOTO VERIFICATION
export class PhotoVerification extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            users : [],
            page : 1,
            perPage:10,
            count_page: 1,
            full_screen_photo:'',
            open_modal:false,
            decline_modal:false,
            decline_user:{

            }
        }
    }
    columns  =[
        {field:'agoraUId',headerName:'Agora UID',  width: 200},
        {field:'first_name',headerName:'First Name',width: 150},
        {field:'middle_name',headerName:'Middle Name',width: 150},
        {field:'last_name',headerName:'Last Name',width: 150},
        {
            field:'photos',
            headerName:"Photo",
            width: 350,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const handleImgClick = (e) => {
                    this.setState({full_screen_photo:e.target.src});
                    this.setState({open_modal:true});
                };
             return (
                 <div>
                     {params.value && params.value.length ? params.value.map((photo) => (
                         <img className='photo-verification-table-img' key={photo.id} src={photo.link} onClick={handleImgClick}  />
                     )) : ''}
                 </div>
             )
            }
        },
        {
            field:'photoVerification',
            headerName:"Verification Status",
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        {params.value ? (
                            <div className='photo-verification-span-yes'>YES</div>
                        ) :  <div className='photo-verification-span-yes no'>NO</div>}
                    </div>
                )
            }
        },
        {
            field:'_id',
            headerName:"Buttons",
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const handleBtnClick = (e) => {
                    const id = e.target.getAttribute('data_id')
                    const attribute = e.target.getAttribute('data_attribute')
                    let users = this.state.users
                    if(attribute && attribute == 'true'){
                        this.setState({decline_modal:false})
                        users = users.map(e =>{
                            if(e._id == id){
                                e.photoVerification = attribute && attribute == 'true' ? true : false
                            }
                            return  e
                        })
                        this.setState({users})
                        axios({
                            method: 'post',
                            url: `${config.SERVER_URL}admin/users/updateUserPhotoVerification`,
                            data:{id,photoVerification:attribute}
                        }).then((response) => {
                            console.log('true')
                        })
                    }else{
                        users.map(e =>{
                            if(e._id == id){
                                this.setState({decline_user:e})
                            }
                        })
                        this.setState({decline_modal:true})
                    }
                };
                return (
                    <div className="button-container">
                        <button className="green-button" data_id={params.value} data_attribute='true' onClick={handleBtnClick}>approve</button>
                        <button className="red-button" data_id={params.value} data_attribute='false' onClick={handleBtnClick}>decline</button>
                    </div>
                )
            }
        },

    ]
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getPhotoVerification`,
        }).then((response) => {
            response.data = response.data.data
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({users})
            if(response.data && response.data.rows && !response.data.rows.length){
                axios({
                    method: 'post',
                    url: `${config.SERVER_URL}admin/users/getPhotoVerification`,
                    data:{current_page:this.state.page,items_per_page:this.state.perPage}
                }).then((response) => {
                    response.data = response.data.data
                    const users = response.data.rows
                    const pages = response.data.pages
                    this.setState({count_page: pages})
                    this.setState({users})
                })
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    paginationChange(event,value){
        const page = value
        this.setState({page:parseInt(page)})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/getPhotoVerification`,
            data:{current_page:page,items_per_page:this.state.perPage,filter:this.state.filter.id}
        }).then((response) => {
            response.data = response.data.data
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({users})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }

    checkPagination(countPage){
        if(countPage > 1){
            return(
                <Pagination  count={this.state.count_page} page={this.state.page} component="div" onChange={this.paginationChange.bind(this)}/>
            )
        }
    }
    CloseButton = () => {
        return (
            <svg
                onClick={(e)=>{
                    this.setState({decline_modal:false})
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
            >
                <path
                    fill="none"
                    d="M0 0h24v24H0z"
                />
                <path
                    fill="#000000"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
            </svg>
        );
    };
    getRowId = (row) => row._id;
    handleChange(e){
        let value = e.target.checked
        let name = e.target.name
        if(name == 'error_photo_1'){
            this.setState(prevState => ({
                decline_user: {
                    ...prevState.decline_user,
                    error_photo_1: this.state.decline_user.error_photo_1 == true ? false : true
                }
            }))
        }else if (name == 'error_photo_2'){
            this.setState(prevState => ({
                decline_user: {
                    ...prevState.decline_user,
                    error_photo_2: this.state.decline_user.error_photo_2 == true ? false : true
                }
            }))
        }else if (name == 'error_photo_3'){
            this.setState(prevState => ({
                decline_user: {
                    ...prevState.decline_user,
                    error_photo_3: this.state.decline_user.error_photo_3 == true ? false : true
                }
            }))
        }else{
            console.log(name,value)
            this.setState(prevState => ({
                decline_user: {
                    ...prevState.decline_user,
                    filter:{
                        ...prevState.decline_user.filter,
                            [name]:value
                    }
                }
            }))
        }
    }
    saveDecline(e){
        this.setState({decline_modal:false})
        let users = this.state.users
        users = users.map(e =>{
            if(e._id == this.state.decline_user._id){
                e.photoVerification = false
            }
            return  e
        })
        this.setState({users})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/updateUserPhotoVerification`,
            data:{id:this.state.decline_user._id,photoVerification:'false',user:this.state.decline_user}
        }).then((response) => {
            console.log('true')
        })
    }

    render() {
        return(
            <div>
                <Box sx={{ height: 650, width: '100%'}}>
                    <DataGrid
                        columns={this.columns}
                        rows={this.state.users}
                        getRowId={this.getRowId}
                        rowHeight={100}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                    />
                </Box>
                {this.checkPagination(this.state.count_page)}
                <Modal open={this.state.open_modal} onClose={() => this.setState({open_modal:false})}>
                    <img
                        src={this.state.full_screen_photo}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </Modal>
                <Modal open={this.state.decline_modal} onClose={() => this.setState({decline_modal:false})}>
                    <div className='decline_modal_div'>
                        <div className='decline_model_content'>
                            <div className='image-row'>
                                {this.state.decline_user.photos && this.state.decline_user.photos.length ?
                                this.state.decline_user.photos.map(e =>{
                                    return(
                                        <div className='checkbox-img' >
                                            {e.link ? <img src={e.link}></img> :<img src='https://planbphoto.com/wp-content/uploads/Serze.jpg'></img>}
                                            <input className='img-checkbox' id={e.link} onChange={this.handleChange.bind(this)}  name={e.link && e.link == this.state.decline_user.photos[0].link ? 'error_photo_1' : (e.link && this.state.decline_user.photos[1] && e.link == this.state.decline_user.photos[1].link  ? 'error_photo_2' : (e.link && this.state.decline_user.photos[2] && e.link == this.state.decline_user.photos[2].link ? 'error_photo_3' : true))}  type='checkbox' checked={e.link && e.link == this.state.decline_user.photos[0].link && this.state.decline_user.error_photo_1 == true ? true : (e.link && this.state.decline_user.photos[1] && e.link == this.state.decline_user.photos[1].link && this.state.decline_user.error_photo_2 == true ? true : (e.link && this.state.decline_user.photos[2] && e.link == this.state.decline_user.photos[2].link && this.state.decline_user.error_photo_3 == true ? true : false))}></input>
                                            <label className='img-checkbox-label' htmlFor={e.link}></label>
                                        </div>
                                    )
                                }) :
                                    <div className='checkbox-img'>
                                        <img src='https://planbphoto.com/wp-content/uploads/Serze.jpg'></img>
                                    </div>
                                }

                            </div>
                            <div className="checkbox-list" style={this.state.decline_user.error_photo_1 == true || this.state.decline_user.error_photo_2 == true || this.state.decline_user.error_photo_3 == true ? {display:"block"} : {display:'none'} }>
                                <div className='list-item'>
                                    <span>3 different photos</span>
                                    <input className='list-item-checkbox' name='different_photos' onChange={this.handleChange.bind(this)}  type='checkbox' checked={this.state.decline_user && this.state.decline_user.filter ? this.state.decline_user.filter.different_photos : true}></input>
                                </div>
                                <div className='list-item'>
                                    <span>Non blurry</span>
                                    <input className='list-item-checkbox' name='non_blurry' onChange={this.handleChange.bind(this)}  type='checkbox' checked={this.state.decline_user && this.state.decline_user.filter ? this.state.decline_user.filter.non_blurry : true}></input>
                                </div>
                                <div className='list-item'>
                                    <span>Same person</span>
                                    <input className='list-item-checkbox' name='same_person' onChange={this.handleChange.bind(this)}  type='checkbox' checked={this.state.decline_user && this.state.decline_user.filter ? this.state.decline_user.filter.same_person : true}></input>
                                </div>
                                <div className='list-item'>
                                    <span>Visible Face</span>
                                    <input className='list-item-checkbox' name='visible_face' onChange={this.handleChange.bind(this)}  type='checkbox' checked={this.state.decline_user && this.state.decline_user.filter ? this.state.decline_user.filter.visible_face : true}></input>
                                </div>
                                <div className='list-item'>
                                    <span>No filter</span>
                                    <input className='list-item-checkbox' name='no_filter' onChange={this.handleChange.bind(this)}  type='checkbox' checked={this.state.decline_user && this.state.decline_user.filter ? this.state.decline_user.filter.no_filter : true}></input>
                                </div>
                                <div className='list-item'>
                                    <span>Minimum 480 pixel resolution</span>
                                    <input className='list-item-checkbox'  name='resolution' onChange={this.handleChange.bind(this)}  type='checkbox' checked={this.state.decline_user && this.state.decline_user.filter ? this.state.decline_user.filter.resolution : true}></input>
                                </div>

                            </div>
                            <div className='close_modal_btn'><this.CloseButton/></div>
                            <div className='decline-user-div-save'>
                                <button onClick={this.saveDecline.bind(this)}>
                                    SAVE
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
//PHOTO VERIFICATION SETTING
export class PhotoVerificationSetting extends React.Component{
    state ={
        emails :'',
        open_modal:false
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getConfig/admin_verification_mail`,
        }).then((response) => {
            const data = response.data
            if(data && data.length){
                let newEmails
                for(let i = 0; i< data.length; i++){
                    if(i == 0){
                        newEmails = data[i]
                    }else{
                        newEmails = newEmails + ','+data[i]
                    }
                }
                this.setState({emails:newEmails})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    saveConfig(){
        let value = this.state.emails ? this.state.emails :[]
        if(value && typeof value == 'string'){
            value = value.split(',')
        }
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/saveConfig`,
            data: {
                type:'admin_verification_mail',
                value:value
            },
        }).then((response) => {
            this.setState({open_modal:true})
            return response.data
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    onChangeEmails(e){
        this.setState({emails:e.target.value})
    }
    CloseButton = () => {
        return (
            <svg
                onClick={(e)=>{
                    this.setState({open_modal:false})
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
            >
                <path
                    fill="none"
                    d="M0 0h24v24H0z"
                />
                <path
                    fill="#000000"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
            </svg>
        );
    };
    render() {
        return(<div className="block-support-setting">
            <div className="support-block-setting-title">
                <label>E-mail addresses to whom letters will be sent</label>
                <div className="support-block-setting-input-block">
                    <input className="support-setting-input" value={this.state.emails} onChange={this.onChangeEmails.bind(this)} type="text"/>
                    <button className="support-setting-button" onClick={this.saveConfig.bind(this)}>SAVE</button>
                </div>

            </div>
                <Modal open={this.state.open_modal} onClose={() => this.setState({open_modal:false})}>
                    <div className='decline_modal_div'>
                        <div className='decline_model_content'>
                            <div className='successfully-modal-title'> Saved successfully</div>
                            <div className='div-successfully-modal-icon'>
                                <CheckCircleOutlineIcon sx={{ color: 'green', width:'200px', height:'200px' }} />
                            </div>
                            <div className='close_modal_btn'><this.CloseButton/></div>
                        </div>
                    </div>
                </Modal>

        </div>)
    }
}
//Account Deletion Requests SETTING
export class AccountDeletionRequestsSetting extends React.Component{
    state ={
        emails :'',
        open_modal:false
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getConfig/admin_deletion_account_request_mail`,
        }).then((response) => {
            const data = response.data
            if(data && data.length){
                let newEmails
                for(let i = 0; i< data.length; i++){
                    if(i == 0){
                        newEmails = data[i]
                    }else{
                        newEmails = newEmails + ','+data[i]
                    }
                }
                this.setState({emails:newEmails})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    saveConfig(){
        let value = this.state.emails ? this.state.emails :[]
        if(value && typeof value == 'string'){
            value = value.split(',')
        }
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/saveConfig`,
            data: {
                type:'admin_deletion_account_request_mail',
                value:value
            },
        }).then((response) => {
            this.setState({open_modal:true})
            return response.data
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    onChangeEmails(e){
        this.setState({emails:e.target.value})
    }
    CloseButton = () => {
        return (
            <svg
                onClick={(e)=>{
                    this.setState({open_modal:false})
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
            >
                <path
                    fill="none"
                    d="M0 0h24v24H0z"
                />
                <path
                    fill="#000000"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
            </svg>
        );
    };
    render() {
        return(<div className="block-support-setting">
            <div className="support-block-setting-title">
                <label>E-mail addresses to whom letters will be sent</label>
                <div className="support-block-setting-input-block">
                    <input className="support-setting-input" value={this.state.emails} onChange={this.onChangeEmails.bind(this)} type="text"/>
                    <button className="support-setting-button" onClick={this.saveConfig.bind(this)}>SAVE</button>
                </div>

            </div>
            <Modal open={this.state.open_modal} onClose={() => this.setState({open_modal:false})}>
                <div className='decline_modal_div'>
                    <div className='decline_model_content'>
                        <div className='successfully-modal-title'> Saved successfully</div>
                        <div className='div-successfully-modal-icon'>
                            <CheckCircleOutlineIcon sx={{ color: 'green', width:'200px', height:'200px' }} />
                        </div>
                        <div className='close_modal_btn'><this.CloseButton/></div>
                    </div>
                </div>
            </Modal>

        </div>)
    }
}
//CREATE NEW USER
export class NewUserPage extends React.Component{
    state = {
        first_name:'',
        last_name:'',
        middle_name:'',
        phone:'',
        password:'',
        change_password:'',
        role:2,

        first_name_invalid:'',
        last_name_invalid:'',
        middle_name_invalid:'',
        phone_invalid:'',
        password_invalid:'',
        change_password_invalid:'',

        password_invalid_text:'',
        invalid_text:"",

        popup_active:false
    }
    handleChange(e){
        let value = e.target.value
        this.setState({
            [e.target.name]:value
        })
    }
    createUser(){
        const {first_name,last_name,middle_name,phone,password,change_password,role} =this.state
        let error
        let regex_password = /(?=.{8,})/

        if(!first_name){
            this.setState({first_name_invalid:true})
            error = 1
        }else{
            this.setState({first_name_invalid:false})
        }
        if(!last_name){
            this.setState({last_name_invalid:true})
            error = 1
        }else{
            this.setState({last_name_invalid:false})
        }
        if(!middle_name){
            this.setState({middle_name_invalid:true})
            error = 1
        }else{
            this.setState({middle_name_invalid:false})
        }
        if(!phone){
            this.setState({phone_invalid:true})
            error = 1
        }else{
            this.setState({phone_invalid:false})
        }
        if(!password){
            this.setState({password_invalid:true})
            error = 1
        }else{
            if(regex_password.test(password)){
                this.setState({password_invalid:false})
                this.setState({password_invalid_text:false})
            }else{
                error = 1
                this.setState({password_invalid_text:true})
                this.setState({password_invalid:true})
                this.setState({invalid_text:'Enter the correct password (8 letters and numbers)'})
            }
        }
        if(!change_password){
            this.setState({change_password_invalid:true})
            error = 1
        }else{
            if(regex_password.test(change_password)){
                this.setState({change_password_invalid:false})
                this.setState({password_invalid_text:false})
            }else{
                error = 1
                this.setState({change_password_invalid:true})
                this.setState({password_invalid_text:true})
                this.setState({invalid_text:'Enter the correct change password (8 letters and numbers)'})
            }
        }
        if(!error){
            if(password && change_password && password !== change_password){
                this.setState({password_invalid:true})
                this.setState({change_password_invalid:true})
                this.setState({password_invalid_text:true})
                this.setState({invalid_text:'Change password not suitable password'})
                error = 1
            }else{
                this.setState({password_invalid_text:false})
            }
            if(!error){
                axios({
                    method: 'post',
                    url: `${config.SERVER_URL}admin/users/createUser`,
                    data:{
                        first_name,last_name,middle_name,phone,password,change_password,role
                    }
                }).then((response) => {
                    this.setState({popup_active:true})
                    setTimeout(() => window.location.pathname ='/', 2500);
                    this.setState({password_invalid_text:false})
                }, (error) => {
                    this.setState({password_invalid_text:true})
                    this.setState({invalid_text:error.response.data.message})
                    if(error && error.response && error.response.data){
                        if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                            localStorage.removeItem("token");
                            window.location.pathname ='/'
                        }
                    }
                });

            }
        }

    }
    render() {
        return(
            <div>
                <div className="user-detail-mail">
                    <div className="user-detail-profile-main-info">
                        <div className="user-detail-profile-info-name">
                            <label>First Name</label>
                            <input type="text" className={this.state.first_name_invalid ? 'invalid' :''} name="first_name" defaultValue={this.state.first_name} onChange={this.handleChange.bind(this)}  autoComplete="off" />
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>Middle Name</label>
                            <input type="text" className={this.state.middle_name_invalid ? 'invalid' :''} name="middle_name" defaultValue={this.state.middle_name} onChange={this.handleChange.bind(this)}   autoComplete="off" />
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>Last Name</label>
                            <input type="text" className={this.state.last_name_invalid ? 'invalid' :''} name="last_name" defaultValue={this.state.last_name}  onChange={this.handleChange.bind(this)}  autoComplete="off" />
                        </div>
                    </div>
                    <div className="user-detail-profile-main-info">
                        <div className="user-detail-profile-info-name">
                            <label>Phone</label>
                            <input type="text" name="phone" className={this.state.phone_invalid ? 'invalid' :''} defaultValue={this.state.phone} onChange={this.handleChange.bind(this)}   autoComplete="off" />
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>Password</label>
                            <input type="text" name="password" className={this.state.password_invalid ? 'invalid' :''} defaultValue={this.state.password} onChange={this.handleChange.bind(this)}    autoComplete="off" />
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>Change password</label>
                            <input type="text" name="change_password" className={this.state.change_password_invalid ? 'invalid' :''} defaultValue={this.state.change_password}  onChange={this.handleChange.bind(this)}   autoComplete="off" />
                        </div>

                    </div><div className="user-detail-profile-main-info">
                    <div className="user-detail-profile-info-name">
                        <label>Role</label>
                        <select  autoComplete="off" name="role" defaultValue={this.state.role} onClick={this.handleChange.bind(this)}   >
                            <option value="2">Moderator</option>
                            <option value="3">Admin</option>
                        </select>
                    </div>
                </div>
                </div>
                <div className="button-create-user">
                    <button onClick={this.createUser.bind(this)}>CREATE</button>
                </div>
                <div className={this.state.password_invalid_text ? 'error-message invalid' :"error-message" }>{this.state.invalid_text}</div>
                <PopupConfirm text="User successfully created" active={this.state.popup_active}>
                </PopupConfirm>
            </div>
        )
    }
}

//MEDIA GALLERY
export class MediaPage extends React.Component{
    state={
        page :1,
        per_page:30,
        count_page: 1,
        media :[]
    }
    paginationChange(event,value){
        const page = value
        this.setState({page:parseInt(page)})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/media`,
            data:{current_page:page,items_per_page:this.state.perPage}
        }).then((response) => {
            const media = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({media})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    checkPagination(countPage){
        if(countPage > 1){
            return(
                <Pagination  count={this.state.count_page} page={this.state.page} component="div" onChange={this.paginationChange.bind(this)}/>
            )
        }
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getMedia`,
        }).then((response) => {
            const data = response.data
            this.setState({media:data.rows})
            this.setState({count_page:data.pages})
            this.setState({count:data.count})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    deleteImage(e){
        let {media} = this.state
        let newMedia =[]
        for(let item of media){
            if(item.id != e){
                newMedia.push(item)
            }
        }
        this.setState({media:newMedia})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/deletePhoto`,
            data:{id:e}
        }).then((response) => {
            axios({
                method: 'post',
                url: `${config.SERVER_URL}admin/users/media`,
                data:{current_page:this.state.page,items_per_page:this.state.perPage}
            }).then((response) => {
                const media = response.data.rows
                const pages = response.data.pages
                this.setState({count_page:pages})
                this.setState({media})
            }, (error) => {
                if(error && error.response && error.response.data){
                    if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                        localStorage.removeItem("token");
                        window.location.pathname ='/'
                    }
                }
            });
        }, (error) => {
        });
    }
    render(){
        return(
            <div>
                <div className="media-page-gallery-body">
                    <div className="media-page-gallery">
                        {this.state.media && this.state.media.length ? this.state.media.map(e => (
                            <div className="media-page-gallery-image-wrapper" key={e.id}>
                                <img key={e.id} src={e.link}></img>
                                <button className="delete-img" data_user_id={e.id} onClick={this.deleteImage.bind(this,e.id)}/>
                            </div>
                            )) :''}
                    </div>
                </div>
                <div className="media-page-pagination">
                    {this.checkPagination(this.state.count_page)}
                </div>
            </div>
        )
    }
}

// USER DETAIL
export class UserDetailPage extends React.Component{
    state ={
        user : {},
        images: [],
        disabled:true,
        user_id : this.props.id
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getUser/${this.props.id}`,
        }).then((response) => {
            const data = response.data
            if(data && data.data && data.data.user){
                this.setState({user:data.data.user})
                this.setState({images:data.data.images})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    blockedUser(e){
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                isBlocked: this.state.user.isBlocked == true ? false : true
            }
        }))
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/blockedUserById`,
            data: {
                id:this.state.user_id,
                isBlocked:this.state.user.isBlocked == true ? false : true
            },
        }).then((response) => {
            return response.data
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    render() {
        return(
            <div>
                <div className="user-detail-mail">
                    <div className="user-detail-block-profile-image">
                        <img src={this.state.user && this.state.user.profilePhoto ? this.state.user.profilePhoto :"https://kca.gov.kg/images/placeHolder.png"}/>
                    </div>
                    <div className="user-detail-profile-main-info">
                        <div className="user-detail-profile-info-name">
                            <label>First Name</label>
                            <input type="text" defaultValue={this.state.user.firstName ? this.state.user.firstName : ''} autoComplete="off" disabled={this.state.disabled}/>
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>Middle Name</label>
                            <input type="text" defaultValue={this.state.user.middleName ? this.state.user.middleName : ''} autoComplete="off" disabled={this.state.disabled}/>
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>Last Name</label>
                            <input type="text" defaultValue={this.state.user.lastName ? this.state.user.lastName : ''} autoComplete="off" disabled={this.state.disabled}/>
                        </div>
                    </div>
                    <div className="user-detail-profile-main-info">
                        <div className="user-detail-profile-info-name">
                            <label>Age</label>
                            <input type="text" defaultValue={this.state.user.age ? this.state.user.age : ''} autoComplete="off" disabled={this.state.disabled}/>
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>Phone</label>
                            <input type="text" defaultValue={this.state.user.phone ? this.state.user.phone : ''} autoComplete="off" disabled={this.state.disabled}/>
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>Uid</label>
                            <input type="text" defaultValue={this.state.user.agoraUId ? this.state.user.agoraUId : ''} autoComplete="off" disabled={this.state.disabled}/>
                        </div>
                    </div>
                    <div className="user-detail-profile-main-info">
                        <div className="user-detail-profile-info-name">
                            <label>is Blocked</label>
                            <input type="checkbox" autoComplete="off" checked={this.state.user.isBlocked ? this.state.user.isBlocked : false}  onChange={this.blockedUser.bind(this)}/>
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>is Deleted</label>
                            <input type="checkbox" autoComplete="off" checked={this.state.user.isDeleted ? this.state.user.isDeleted : false} disabled={this.state.disabled} />
                        </div>
                        <div className="user-detail-profile-info-name">
                            <label>is Phone Verify</label>
                            <input type="checkbox" autoComplete="off" checked={this.state.user.isPhoneVerify ? this.state.user.isPhoneVerify : false} disabled={this.state.disabled} />
                        </div>
                    </div>
            </div>
                <div className="block-name">Bio</div>
                <div className="user-detail-block-bio">
                <textarea defaultValue="a1234wdwad wadwa121313 qewad wads zd akjd kajwdjasoi djskna dj sadio jwaijd iowjad jsaiod sad aswa dwadsad sad sad xzc awd sc zc aw dsac xzc adw a dasd wadsa" disabled={this.state.disabled} />
            </div>
                <div className="block-name">Gallery</div>
                <div className="user-detail-block-gallery">
                    {this.state.images && this.state.images.length ? this.state.images.map(e => (
                        <img src={e.link} key={e.id} />
                    )) : <div className='user-detail-text-nothing-results'>There is no gallery</div>}
                </div>
                <div className="block-name">Activities</div>
                <div className="user-detail-block-activities">
                    {this.state.user.activities && this.state.user.activities.length ? this.state.user.activities.map(e => (
                        <button key={e} >{e}</button>
                    )) : <div className='user-detail-text-nothing-results'>There is no activities</div>}
                </div>
                <div className="block-name">LifeStyle</div>
                <div className="user-detail-block-lifeStyle">
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Recreational activities</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="user-detail-profile-main-info-accordion">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Smoker</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.recreationalActivities && this.state.user.lifeStyle.recreationalActivities.smoker ? this.state.user.lifeStyle.recreationalActivities.smoker : '' } disabled={this.state.disabled} />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Alcohol</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.recreationalActivities && this.state.user.lifeStyle.recreationalActivities.alcohol ? this.state.user.lifeStyle.recreationalActivities.alcohol : '' } disabled={this.state.disabled} />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Drugs</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.recreationalActivities && this.state.user.lifeStyle.recreationalActivities.drugs ? this.state.user.lifeStyle.recreationalActivities.drugs : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                            <div className="user-detail-profile-main-info-accordion">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Gambling</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.recreationalActivities && this.state.user.lifeStyle.recreationalActivities.gambling ? this.state.user.lifeStyle.recreationalActivities.gambling : '' } disabled={this.state.disabled} />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Friendly</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.recreationalActivities && this.state.user.lifeStyle.recreationalActivities.friendly ? this.state.user.lifeStyle.recreationalActivities.friendly : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Accomodation Type</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className="user-detail-profile-main-info-accordion-small">
                                    <div className="user-detail-profile-info-name-accordion">
                                        <label>Accomodation type</label>
                                        <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.accomodationType  ? this.state.user.lifeStyle.accomodationType : '' } disabled={this.state.disabled} />
                                    </div>
                                </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Children</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className="user-detail-profile-main-info-accordion-small">
                                    <div className="user-detail-profile-info-name-accordion">
                                        <label> Kids amount</label>
                                        <input type="number" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.children &&  this.state.user.lifeStyle.children.kidsAmount ? this.state.user.lifeStyle.children.kidsAmount : '' } disabled={this.state.disabled} />
                                    </div>
                                </div>
                                <div className="user-detail-profile-main-info-accordion-small">
                                    <div className="user-detail-profile-info-name-accordion">
                                        <label> Choice list</label>
                                        <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.children &&  this.state.user.lifeStyle.children.choiceList ? this.state.user.lifeStyle.children.choiceList : '' } disabled={this.state.disabled} />
                                    </div>
                                </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Family status</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="user-detail-profile-main-info-accordion-small">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label> Type</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.familyStatus  ? this.state.user.lifeStyle.familyStatus : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Pets</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="user-detail-profile-main-info-accordion-small">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label> Pets amount</label>
                                    <input type="number" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.pets && this.state.user.lifeStyle.pets.petsAmount  ? this.state.user.lifeStyle.pets.petsAmount : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                            <div className="user-detail-profile-main-info-accordion-small">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label> Pets choice list</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.pets && this.state.user.lifeStyle.pets.petsChoiseList  ? this.state.user.lifeStyle.pets.petsChoiseList : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Scholl</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {this.state.user.lifeStyle && this.state.user.lifeStyle.scholl && this.state.user.lifeStyle.scholl.length  ? this.state.user.lifeStyle.scholl.map(e => (
                                    {e}
                                )) : '' }
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >SchollTwo</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {this.state.user.lifeStyle && this.state.user.lifeStyle.schollTwo && this.state.user.lifeStyle.schollTwo.length  ? this.state.user.lifeStyle.schollTwo.map(e => (
                                    {e}
                                )) : '' }
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Diploma</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {this.state.user.lifeStyle && this.state.user.lifeStyle.diploma && this.state.user.lifeStyle.diploma.length  ? this.state.user.lifeStyle.diploma.map(e => (
                                    {e}
                                )) : '' }
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Longest relationship</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="user-detail-profile-main-info-accordion-small">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label> Type</label>
                                    <input type="text" autoComplete="off"  defaultValue={this.state.user.lifeStyle && this.state.user.lifeStyle.longestRelationship   ? this.state.user.lifeStyle.longestRelationship : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="block-name">Music genres</div>
                <div className="user-detail-block-activities">
                    {this.state.user.musicGenres && this.state.user.musicGenres.length ? this.state.user.musicGenres.map(e => (
                        <button key={e} >{e}</button>
                    )) : <div className='user-detail-text-nothing-results'>There is no Music genres</div>}
                </div>
                <div className="block-name">Profession</div>
                <div className="user-detail-block-lifeStyle">
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Self employed</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="user-detail-profile-main-info-accordion">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Profession</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.selfEmployed &&  this.state.user.profession.selfEmployed.profession  ? this.state.user.profession.selfEmployed.profession : '' } disabled={this.state.disabled} />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Work load</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.selfEmployed &&  this.state.user.profession.selfEmployed.workLoad  ? this.state.user.profession.selfEmployed.workLoad : '' } disabled={this.state.disabled} />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Business trips</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.selfEmployed &&  this.state.user.profession.selfEmployed.businessTrips  ? this.state.user.profession.selfEmployed.businessTrips : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                            <div className="user-detail-profile-main-info-accordion">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Income</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.selfEmployed &&  this.state.user.profession.selfEmployed.income  ? this.state.user.profession.selfEmployed.income : '' } disabled={this.state.disabled} />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>First responders</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.selfEmployed &&  this.state.user.profession.selfEmployed.firstResponders  ? this.state.user.profession.selfEmployed.firstResponders : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Employment</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="user-detail-profile-main-info-accordion-small">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Profession</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.employment &&  this.state.user.profession.employment.profession  ? this.state.user.profession.employment.profession : '' } disabled={this.state.disabled}  />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Income</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.employment &&  this.state.user.profession.employment.income  ? this.state.user.profession.employment.income : '' } disabled={this.state.disabled} />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>First responders</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.employment &&  this.state.user.profession.employment.firstResponders  ? this.state.user.profession.employment.firstResponders : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                            <div className="user-detail-profile-main-info-accordion-small">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Work load</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.employment &&  this.state.user.profession.employment.workLoad  ? this.state.user.profession.employment.workLoad : '' } disabled={this.state.disabled} />
                                </div>
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Business trips</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.employment &&  this.state.user.profession.employment.businessTrips  ? this.state.user.profession.employment.businessTrips : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Unemployment</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="user-detail-profile-main-info-accordion-small">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label>Type</label>
                                    <input type="checkbox" autoComplete="off" checked={this.state.user.profession && this.state.user.profession.unemployment  ? this.state.user.profession.unemployment : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Student</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="user-detail-profile-main-info-accordion-small">
                                <div className="user-detail-profile-info-name-accordion">
                                    <label> Type</label>
                                    <input type="text" autoComplete="off" defaultValue={this.state.user.profession && this.state.user.profession.student   ? this.state.user.profession.student : '' } disabled={this.state.disabled} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="block-name">Other</div>
                <div className="user-detail-block-other">
                    <div className="user-detail-profile-main-info-accordion-small">
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Body type</label>
                            <input type="text" autoComplete="off" defaultValue={this.state.user.bodyType ? this.state.user.bodyType : '' } disabled={this.state.disabled} />
                        </div>
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Eyer color</label>
                            <input type="text" autoComplete="off" defaultValue={this.state.user.eyeColor ? this.state.user.eyeColor : '' } disabled={this.state.disabled} />
                        </div>
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Hair color</label>
                            <input type="text" autoComplete="off" defaultValue={this.state.user.hairColor ? this.state.user.hairColor : '' } disabled={this.state.disabled} />
                        </div>
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Interests</label>
                            <input type="text" autoComplete="off" defaultValue={this.state.user.interests ? this.state.user.interests : '' } disabled={this.state.disabled} />
                        </div>
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Sexuality</label>
                            <input type="text" autoComplete="off"  defaultValue={this.state.user.sexuality ? this.state.user.sexuality : '' } disabled={this.state.disabled} />
                        </div>
                    </div>
                    <div className="user-detail-profile-main-info-accordion-small">
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Ethnicity</label>
                            <input type="text" autoComplete="off" defaultValue={this.state.user.ethnicity ? this.state.user.ethnicity : '' } disabled={this.state.disabled} />
                        </div>
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Gender</label>
                            <input type="text" autoComplete="off" defaultValue={this.state.user.gender ? this.state.user.gender : '' } disabled={this.state.disabled} />
                        </div>
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Height</label>
                            <input className='padding-active' type="text" autoComplete="off" defaultValue={this.state.user.height && this.state.user.height.value ? this.state.user.height.value : '' } disabled={this.state.disabled} />
                            <label  className="label-count">{this.state.user.height && this.state.user.height.units ? this.state.user.height.units : '' }</label>
                        </div>
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Religion</label>
                            <input type="text" autoComplete="off" defaultValue={this.state.user.religion ? this.state.user.religion : '' } disabled={this.state.disabled} />
                        </div>
                        <div className="user-detail-profile-info-name-accordion">
                            <label> Skin color</label>
                            <input type="text" autoComplete="off" defaultValue={this.state.user.skinColor ? this.state.user.skinColor : '' } disabled={this.state.disabled} />
                        </div>

                    </div>
                </div>
            </div>)
    }
}
//SUPPORT SETTING
export class SupportSettingPage extends React.Component{
    state ={
        emails :'',
        open_modal:false,
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getConfig/admin_question_mail`,
        }).then((response) => {
            const data = response.data
            if(data && data.length){
                let newEmails
                for(let i = 0; i< data.length; i++){
                    if(i == 0){
                        newEmails = data[i]
                    }else{
                        newEmails = newEmails + ','+data[i]
                    }
                }
                this.setState({emails:newEmails})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    saveConfig(){
        let value = this.state.emails ? this.state.emails :[]
        if(value && typeof value == 'string'){
            value = value.split(',')
        }
         axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/saveConfig`,
            data: {
                type:'admin_question_mail',
                value:value
            },
        }).then((response) => {
             this.setState({open_modal:true})
             return response.data
        }, (error) => {
             if(error && error.response && error.response.data){
                 if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                     localStorage.removeItem("token");
                     window.location.pathname ='/'
                 }
             }
        });
    }
    onChangeEmails(e){
        this.setState({emails:e.target.value})
    }
    CloseButton = () => {
        return (
            <svg
                onClick={(e)=>{
                    this.setState({open_modal:false})
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
            >
                <path
                    fill="none"
                    d="M0 0h24v24H0z"
                />
                <path
                    fill="#000000"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
            </svg>
        );
    };
    render() {
        return(<div className="block-support-setting">
            <div className="support-block-setting-title">
                <label>E-mail addresses to whom letters will be sent</label>
                <div className="support-block-setting-input-block">
                    <input className="support-setting-input" value={this.state.emails} onChange={this.onChangeEmails.bind(this)} type="text"/>
                    <button className="support-setting-button" onClick={this.saveConfig.bind(this)}>SAVE</button>
                </div>

            </div>
            <Modal open={this.state.open_modal} onClose={() => this.setState({open_modal:false})}>
                <div className='decline_modal_div'>
                    <div className='decline_model_content'>
                        <div className='successfully-modal-title'> Saved successfully</div>
                        <div className='div-successfully-modal-icon'>
                            <CheckCircleOutlineIcon sx={{ color: 'green', width:'200px', height:'200px' }} />
                        </div>
                        <div className='close_modal_btn'><this.CloseButton/></div>
                    </div>
                </div>
            </Modal>
        </div>)
    }
}
//FACE VERIFY SETTING
export class FaceVerifySettingPage extends React.Component{
    state ={
        emails :'',
        open_modal:false,
        gallery : []
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/getFaceVerifySetting`,
        }).then((response) => {
            const data = response.data.data
            if(data && data.length){
                this.setState({gallery:data})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    handleImageSelect = (event) => {
        const formData = new FormData();
        formData.append("photo", event.target.files[0]);
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/saveFaceVerifySetting`,
            data:formData,
                }).then((response) => {
                    this.setState({gallery:[{src:response.data.success,id:response.data.id},...this.state.gallery]})
                    return response.data
                }, (error) => {
                    if(error && error.response && error.response.data){
                        if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                            localStorage.removeItem("token");
                            window.location.pathname ='/'
                        }
                    }
                });
    };
    deleteImage =(e) =>{
        let {gallery} = this.state
        let newMedia =[]
        for(let item of gallery){
            if(item.id != e){
                newMedia.push(item)
            }
        }
        this.setState({gallery:newMedia})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/deletePhotoInFaceVerifySetting`,
            data:{id:e},
        }).then((response) => {
            return response.data
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    render() {
        return(
            <div className='gallery-face-verify'>
                <div className='download-div'>
                    <input
                        className='download-file-input'
                        type="file"
                        accept="image/*"
                        id='fileInput'
                        onChange={this.handleImageSelect.bind(this)}
                    />
                    <label
                        htmlFor="fileInput"
                        className='download-file-button'
                    >
                        Upload Image
                    </label>
                </div>
                <div className='setting-gallery'>
                    { this.state.gallery.length ? this.state.gallery.map((image, index) => (
                        <div className='setting-image-div-contain'>
                            <img key={index} className='setting-image' src={image.src} alt={image.alt} />
                            <button className="delete-img-face-verify" data_user_id={image.id}
                                onClick={this.deleteImage.bind(this,image.id)}
                            />
                        </div>
                        )) : 'No images, please add an image to display'}
                </div>
            </div>
        )
    }
}
//SUPPORT
export class SupportList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            reports : [],
            page : 1,
            perPage:10,
            type:'all',
            count_page: 1,
            popup:false,
            popup_message:'',
            popup_content:{
                report:'',
                type:'',
                userFrom:{
                    profilePhoto:''
                },
                userTo:{
                    profilePhoto:''
                }
            },
            filter:{
                id:'All'
            },
            filter_count:{
                all:0,
                account:0,
                customer:0,
                payment:0,
                profile_delete:0,
            }
        }
        this.popupClose = this.popupClose.bind(this)
    }
    columns  =[
        {field:'_id',headerName:'ID',width: 200,},
        {field:'email',headerName:'Email',width: 200},
        {field:"question",headerName:"Question",width: 500, height:200},
        {field:"type",headerName:"Type Report",width: 200},
    ]
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/questions`,
        }).then((response) => {
            response.data = response.data.data
            const reports = response.data.rows
            const pages = response.data.pages

            if(response.data.allCount){
                this.setState({filter_count:{
                        all:response.data.allCount.all,
                        account:response.data.allCount.account,
                        customer:response.data.allCount.customer,
                        payment:response.data.allCount.payment,
                        profile_delete:response.data.allCount.profile_delete,

                    }})
            }
            this.setState({count_page:pages})
            this.setState({reports})
            if(response.data && response.data.rows && !response.data.rows.length){
                    axios({
                        method: 'post',
                        url: `${config.SERVER_URL}admin/users/questions`,
                        data:{current_page:this.state.page,items_per_page:this.state.perPage,filter:this.state.filter.id}
                    }).then((response) => {
                        response.data = response.data.data
                        const reports = response.data.rows
                        const pages = response.data.pages
                        this.setState({count_page:pages})
                        this.setState({reports})
                        if(response.data.allCount){
                            this.setState({filter_count:{
                                    all:response.data.allCount.all,
                                    account:response.data.allCount.account,
                                    customer:response.data.allCount.customer,
                                    payment:response.data.allCount.payment,
                                    profile_delete:response.data.allCount.profile_delete,

                                }})
                        }
                    }, (error) => {
                        if(error && error.response && error.response.data){
                            if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                                localStorage.removeItem("token");
                                window.location.pathname ='/'
                            }
                        }
                    });
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    paginationChange(event,value){
        const page = value
        this.setState({page:parseInt(page)})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/questions`,
            data:{current_page:page,items_per_page:this.state.perPage,filter:this.state.filter.id}
        }).then((response) => {
            response.data = response.data.data
            const reports = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({reports})
            if(response.data.allCount){
                this.setState({filter_count:{
                        all:response.data.allCount.all,
                        account:response.data.allCount.account,
                        customer:response.data.allCount.customer,
                        payment:response.data.allCount.payment,
                        profile_delete:response.data.allCount.profile_delete,

                    }})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }

    checkPagination(countPage){
        if(countPage > 1){
            return(
                <Pagination  count={this.state.count_page} page={this.state.page} component="div" onChange={this.paginationChange.bind(this)}/>
            )
        }
    }
    onClickMessage = async(event)=>{
        let id = event.row._id
        await axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/getQuestion`,
            data:{id}
        }).then((response) => {
            const data = response.data
            this.setState({popup_content:data})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
        this.setState({popup:true})
    }
    popupClose(value){
        this.setState({
            popup:value
        })
    }
    clickButtonFilter = async (event)=>{
        let filter = event.target.id
        this.setState({filter:{id:event.target.id}})
        this.setState({page:1})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/questions`,
            data:{current_page:1,items_per_page:this.state.perPage,filter:filter}
        }).then((response) => {
            response.data = response.data.data
            const reports = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({reports})
            if(response.data.allCount){
                this.setState({filter_count:{
                        all:response.data.allCount.all,
                        account:response.data.allCount.account,
                        customer:response.data.allCount.customer,
                        payment:response.data.allCount.payment,
                        profile_delete:response.data.allCount.profile_delete,

                    }})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    render() {
        return(
            <div>
                <div className="report-buttons-type">
                    <ButtonGroup color="primary" aria-label="outlined primary button group" size="large" onClick={this.clickButtonFilter.bind(this)}>
                        <Button  id="All" className={this.state.filter.id && this.state.filter.id == 'All'?"filter-reports-button active" : "filter-reports-button"} data-active="true">All<br></br>{this.state.filter_count.all} </Button>
                        <Button id="Account Support" className={this.state.filter.id && this.state.filter.id == 'Account Support'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Account Support<br></br>{this.state.filter_count.account}</Button>
                        <Button id="Customer Support" className={this.state.filter.id && this.state.filter.id == 'Customer Support'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Customer Support<br></br>{this.state.filter_count.customer}</Button>
                        <Button id="Payment Support" className={this.state.filter.id && this.state.filter.id == 'Payment Support'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Payment Support<br></br>{this.state.filter_count.payment}</Button>
                        <Button id="Account Delete Support" className={this.state.filter.id && this.state.filter.id == 'Account Delete Support'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Account Delete Support<br></br>{this.state.filter_count.profile_delete}</Button>
                    </ButtonGroup>
                </div>
                {/*<Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align="center" component={'span'}>*/}
                <Box sx={{ height: 650, width: '100%' }}>
                    <DataGrid
                        columns={this.columns}
                        rows={this.state.reports}
                        onCellClick={this.onClickMessage.bind(this)}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                </Box>
                {/*</Typography>*/}
                {this.checkPagination(this.state.count_page)}
                <PopupSupport active={this.state.popup} setActive={this.popupClose} reportData={this.state.popup_content}>
                </PopupSupport>
            </div>
        )
    }
}



//REPORTS
export class MessageList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            reports : [],
            page : 1,
            perPage:10,
            type:'all',
            count_page: 1,
            popup:false,
            popup_message:'',
            popup_content:{
                report:'',
                type:'',
                userFrom:{
                    profilePhoto:''
                },
                userTo:{
                    profilePhoto:''
                }
            },
            filter:{
              id:'All'
            },
            filter_count:{
                all:0,
                photos:0,
                name:0,
                behavior:0,
                other:0,
            }
        }
        this.popupClose = this.popupClose.bind(this)
    }
    columns  =[
        {field:'fromUser',headerName:'Who Created Name',  width: 200},
        {field:'fromUserUid',headerName:'Who Created Uid',width: 200},
        {field:'toUser',headerName:"Whom Created Name",width: 200},
        {field:'toUserUid',headerName:"Whom Created Uid",width: 200},
        {field:"report",headerName:"Report",width: 500,height:300},
        {field:"type",headerName:"Type Report",width: 200},
    ]
    componentDidMount() {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}admin/users/reports`,
        }).then((response) => {
            response.data = response.data.data
            const reports = response.data.rows
            const pages = response.data.pages
            if(response.data.allCount){
                this.setState({filter_count:{
                    all:response.data.allCount.all,
                        photos:response.data.allCount.photos,
                        name:response.data.allCount.name,
                        behavior:response.data.allCount.behavior,
                        other:response.data.allCount.other,

                    }})
            }
            this.setState({count_page:pages})
            this.setState({reports})
            if(response.data && response.data.rows && !response.data.rows.length){
                axios({
                    method: 'post',
                    url: `${config.SERVER_URL}admin/users/reports`,
                    data:{current_page:this.state.page,items_per_page:this.state.perPage,filter:this.state.filter.id}
                }).then((response) => {
                    response.data = response.data.data
                    const reports = response.data.rows
                    const pages = response.data.pages
                    this.setState({count_page: pages})
                    this.setState({reports})
                    if (response.data.allCount) {
                        this.setState({
                            filter_count: {
                                all: response.data.allCount.all,
                                photos: response.data.allCount.photos,
                                name: response.data.allCount.name,
                                behavior: response.data.allCount.behavior,
                                other: response.data.allCount.other,

                            }
                        })
                    }
                })
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    paginationChange(event,value){
        const page = value
        this.setState({page:parseInt(page)})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/reports`,
            data:{current_page:page,items_per_page:this.state.perPage,filter:this.state.filter.id}
        }).then((response) => {
            response.data = response.data.data
            const reports = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({reports})
            if(response.data.allCount){
                this.setState({filter_count:{
                        all:response.data.allCount.all,
                        photos:response.data.allCount.photos,
                        name:response.data.allCount.name,
                        behavior:response.data.allCount.behavior,
                        other:response.data.allCount.other,

                    }})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }

    checkPagination(countPage){
        if(countPage > 1){
            return(
                <Pagination  count={this.state.count_page} page={this.state.page} component="div" onChange={this.paginationChange.bind(this)}/>
            )
        }
    }
    onClickMessage = async(event)=>{
        let id = event.row.id
        await axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/getReport`,
            data:{id}
        }).then((response) => {
            const data = response.data
            console.log(data)
            this.setState({popup_content:data})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
        this.setState({popup:true})
    }
    popupClose(value){
        this.setState({
            popup:value
        })
    }
    clickButtonFilter = async (event)=>{
        let filter = event.target.id
        this.setState({filter:{id:event.target.id}})
        this.setState({page:1})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/reports`,
            data:{current_page:1,items_per_page:this.state.perPage,filter:filter}
        }).then((response) => {
            response.data = response.data.data
            const reports = response.data.rows
            const pages = response.data.pages
            this.setState({count_page:pages})
            this.setState({reports})
            if(response.data.allCount){
                this.setState({filter_count:{
                        all:response.data.allCount.all,
                        photos:response.data.allCount.photos,
                        name:response.data.allCount.name,
                        behavior:response.data.allCount.behavior,
                        other:response.data.allCount.other,

                    }})
            }
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    render() {
        return(
            <div>
                <div className="report-buttons-type">
                    <ButtonGroup color="primary" aria-label="outlined primary button group" size="large" onClick={this.clickButtonFilter.bind(this)}>
                        <Button  id="All" className={this.state.filter.id && this.state.filter.id == 'All'?"filter-reports-button active" : "filter-reports-button"} data-active="true">All<br></br>{this.state.filter_count.all} </Button>
                        <Button id="photos" className={this.state.filter.id && this.state.filter.id == 'photos'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Photos<br></br>{this.state.filter_count.photos}</Button>
                        <Button id="name" className={this.state.filter.id && this.state.filter.id == 'name'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Name<br></br>{this.state.filter_count.name}</Button>
                        <Button id="behavior" className={this.state.filter.id && this.state.filter.id == 'behavior'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Behavior<br></br>{this.state.filter_count.behavior}</Button>
                        <Button id="other" className={this.state.filter.id && this.state.filter.id == 'other'?"filter-reports-button active" : "filter-reports-button"}  data-active="false">Other<br></br>{this.state.filter_count.other}</Button>
                    </ButtonGroup>
                </div>
                {/*<Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align="center" component={'span'}>*/}
                <Box sx={{ height: 650, width: '100%'}}>
                    <DataGrid
                        columns={this.columns}
                        rows={this.state.reports}
                        onCellClick={this.onClickMessage.bind(this)}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                </Box>
                {/*</Typography>*/}
                {this.checkPagination(this.state.count_page)}
                <Popup active={this.state.popup} setActive={this.popupClose} reportData={this.state.popup_content}>
                </Popup>
            </div>
        )
    }
}


export class UserList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            users : [],
            page : 1,
            perPage:10,
            countPages :1,
            filter:'',
            role : getUserRoleInJWT(localStorage.getItem('token')),
            getAll:false,
        }
    }
    columns  =[
        {field:'agoraUId',headerName:'UID',width:200},
        {field:'role',headerName:'Role', editable:true,width:200},
        {field:'firstName',headerName:"First Name", editable:true,width:200},
        {field:'lastName',headerName:"Last Name", editable:true,width:200},
        {field:"userName",headerName:"User Name", editable:true,width:200},
        {field:"phone",headerName:"Phone", editable:true,width:200},
        {field:'isBlocked',headerName:"Blocked", editable:true, sortable:false,
            renderCell:(cellValues)=>{
            if(cellValues.row.isBlocked){
                return(
                    <CheckIcon />
                )
            }else{
                return(
                    <CloseSharpIcon />
                )
            }
        }
        },
        {field:"isDeleted",headerName:"Deleted", editable:true,sortable:false,
            renderCell:(cellValues)=>{
                if(cellValues.row.isDeleted){
                    return(
                        <CheckIcon />
                    )
                }else{
                    return(
                        <CloseSharpIcon />
                    )
                }
            }
        }
    ]
    componentDidMount() {
        if(this.state.getAll == false){
            this.setState({getAll:true})
            axios({
                method: 'get',
                url: `${config.SERVER_URL}admin/users/getAllUsers`,
            }).then((response) => {
                response.data = response.data.data
                if(response.data && response.data && response.data.rows.length){
                    response.data.rows = response.data.rows.map(i => {
                        if(i.role == 1){
                            i.role ='User'
                        }else if (i.role == 2){
                            i.role = 'Moderator'
                        }else if (i.role == 3){
                            i.role = 'Administrator'
                        }
                        i.agoraUId = i.agoraUId ? i.agoraUId :'--'
                        i.firstName = i.firstName ? i.firstName :'--'
                        i.lastName = i.lastName ? i.lastName :'--'
                        i.userName = i.userName ? i.userName :'--'
                        return i
                    })
                }else{
                    axios({
                        method: 'post',
                        url: `${config.SERVER_URL}admin/users/getAllUsers`,
                        data:{current_page:0,items_per_page:this.state.perPage,filter:this.state.filter}
                    }).then((response) => {
                        response.data = response.data.data
                        if(response.data && response.data && response.data.rows.length){
                            response.data.rows = response.data.rows.map(i => {
                                if(i.role == 1){
                                    i.role ='User'
                                }else if (i.role == 2){
                                    i.role = 'Moderator'
                                }else if (i.role == 3){
                                    i.role = 'Administrator'
                                }
                                i.agoraUId = i.agoraUId ? i.agoraUId :'--'
                                i.firstName = i.firstName ? i.firstName :'--'
                                i.lastName = i.lastName ? i.lastName :'--'
                                i.userName = i.userName ? i.userName :'--'

                                return i
                            })
                        }
                        const users = response.data.rows
                        const pages = response.data.pages
                        this.setState({countPages:pages})
                        this.setState({users})
                    }, (error) => {
                        if(error && error.response && error.response.data){
                            if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                                localStorage.removeItem("token");
                                window.location.pathname ='/'
                            }
                        }
                    });
                }
                const users = response.data.rows
                const pages = response.data.pages

                this.setState({countPages:pages})
                this.setState({users})
            }, (error) => {
                if(error && error.response && error.response.data){
                    if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                        localStorage.removeItem("token");
                        window.location.pathname ='/'
                    }
                }
            });
        }

    }
    paginationChange(event,value){
        const page = value
        this.setState({page:parseInt(page)})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/getAllUsers`,
            data:{current_page:page,items_per_page:this.state.perPage,filter:this.state.filter}
        }).then((response) => {
            response.data = response.data.data
            if(response.data && response.data && response.data.rows && response.data.rows.length){
                response.data.rows = response.data.rows.map(i => {
                    if(i.role == 1){
                        i.role ='User'
                    }else if (i.role == 2){
                        i.role = 'Moderator'
                    }else if (i.role == 3){
                        i.role = 'Administrator'
                    }
                    i.agoraUId = i.agoraUId ? i.agoraUId :'--'
                    i.firstName = i.firstName ? i.firstName :'--'
                    i.lastName = i.lastName ? i.lastName :'--'
                    i.userName = i.userName ? i.userName :'--'

                    return i
                })
            }
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({countPages:pages})
            this.setState({users})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }

    checkPagination(countPage){
        if(countPage > 1){
            return(
                <Pagination  count={this.state.countPages} page={this.state.page} component="div" onChange={this.paginationChange.bind(this)}/>
            )
        }
    }
    filterChange = (e) => {
        this.setState({ filter: e.currentTarget.value })
    }
    searchFilter(){
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/getAllUsers`,
            data:{current_page:0,items_per_page:this.state.perPage,filter:this.state.filter}
        }).then((response) => {
            response.data = response.data.data
            if(response.data && response.data && response.data.rows.length){
                response.data.rows = response.data.rows.map(i => {
                    if(i.role == 1){
                        i.role ='User'
                    }else if (i.role == 2){
                        i.role = 'Moderator'
                    }else if (i.role == 3){
                        i.role = 'Administrator'
                    }
                    i.agoraUId = i.agoraUId ? i.agoraUId :'--'
                    i.firstName = i.firstName ? i.firstName :'--'
                    i.lastName = i.lastName ? i.lastName :'--'
                    i.userName = i.userName ? i.userName :'--'

                    return i
                })
            }
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({countPages:pages})
            this.setState({users})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    clearFilterText(){
        this.setState({ filter:''})
        axios({
            method: 'post',
            url: `${config.SERVER_URL}admin/users/getAllUsers`,
            data:{current_page:0,items_per_page:this.state.perPage}
        }).then((response) => {
            response.data = response.data.data
            if(response.data && response.data && response.data.rows.length){
                response.data.rows = response.data.rows.map(i => {
                    if(i.role == 1){
                        i.role ='User'
                    }else if (i.role == 2){
                        i.role = 'Moderator'
                    }else if (i.role == 3){
                        i.role = 'Administrator'
                    }
                    i.agoraUId = i.agoraUId ? i.agoraUId :'--'
                    i.firstName = i.firstName ? i.firstName :'--'
                    i.lastName = i.lastName ? i.lastName :'--'
                    i.userName = i.userName ? i.userName :'--'

                    return i
                })
            }
            const users = response.data.rows
            const pages = response.data.pages
            this.setState({countPages:pages})
            this.setState({users})
        }, (error) => {
            if(error && error.response && error.response.data){
                if(error.response.data.statusCode == 401 || error.response.data.message == "invalid token"){
                    localStorage.removeItem("token");
                    window.location.pathname ='/'
                }
            }
        });
    }
    toUserDetailPage(e){
        let id = e.row._id
        window.location.pathname = `/userDetail/${id}`
    }
    createUserButton(){
        return(
            <div className="create-new-user">
                <a href="/create_user">Create User</a>
                </div>
        )
    }
    render() {
        return(
            <div>
                { this.state.role && this.state.role == 3? <this.createUserButton/> :''}
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
                >
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <SearchIcon color="inherit" sx={{ display: "block" }} />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    placeholder="Search by phone number, or user UID"
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: { fontSize: "default" },
                                    }}
                                    variant="standard"
                                    onChange={this.filterChange}
                                    value = {this.state.filter}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" sx={{ mr: 1 }} onClick ={this.searchFilter.bind(this)}>
                                    Find User
                                </Button>
                                <Tooltip title="Reload" onClick={this.clearFilterText.bind(this)}>
                                    <IconButton>
                                        <RefreshIcon color="inherit" sx={{ display: "block" }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                {/*<Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align="center" component={'span'}>*/}
                    <Box sx={{ height: 650, width: '100%' }}>
                        <DataGrid
                            columns={this.columns}
                            rows={this.state.users}
                            onCellClick={this.toUserDetailPage}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            checkboxSelection
                        />
                    </Box>
                {/*</Typography>*/}
                {this.checkPagination(this.state.countPages)}
            </div>
        )
    }
}
function getUserRoleInJWT (token) {
    if(token){
         token = JSON.parse(token);
         token = token?.token
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let payload =JSON.parse(jsonPayload)
        return payload?.role;
    }
};
