module.exports = {
    SERVER_URL:"https://www.rojarose.com/",

    SUPPORT_TABS_LIST:[{name:"List",link:'/support',select :true},{name:"Setting",link:'/supportSetting',select:false}],
    SUPPORT_TABS_SETTING:[{name:"List",link:'/support',select :false,key:'supportList'},{name:"Setting",link:'/supportSetting',select:true,key:'supportSetting'}],

    FACE_VERIFY_TABS_LIST:[{name:"List",link:'/faceVerify',select :true},{name:"Setting",link:'/faceVerifySetting',select:false}],
    FACE_VERIFY_TABS_SETTING:[{name:"List",link:'/faceVerify',select :false,key:'faceVerify'},{name:"Setting",link:'/faceVerifySetting',select:true,key:'faceVerifySetting'}],

    PHOTO_VERIFICATION_TABS_LIST:[{name:"List",link:'/photoVerification',select :true},{name:"Setting",link:'/photoVerificationSetting',select:false}],
    PHOTO_VERIFICATION_TABS_SETTING:[{name:"List",link:'/photoVerification',select :false,key:'photoVerification'},{name:"Setting",link:'/photoVerificationSetting',select:true,key:'photoVerificationSetting'}],
    ACCOUNT_DELETION_REQUESTS_TABS_LIST:[{name:"List",link:'/accountsupport',select :false,key:'accountsupport'},{name:"Setting",link:'/accountsupportSetting',select:true,key:'accountsupportSetting'}],
    ACCOUNT_DELETION_REQUESTS_TABS_SETTING:[{name:"List",link:'/accountsupport',select :false,key:'accountsupport'},{name:"Setting",link:'/accountsupportSetting',select:true,key:'accountsupportSetting'}],

}
