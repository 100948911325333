import * as React from "react";
import Paper from "@mui/material/Paper";
import {FaceVerifySettingPage} from "../API/api-requst";

export default function FaceVerificationSettingPage() {
    return (
        <Paper sx={{ maxWidth: "auto", margin: "auto", overflow: "hidden" }}>
            <FaceVerifySettingPage/>
        </Paper>
    );
}
