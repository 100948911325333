import * as React from "react";
import '../css/policy.css';

export default function Community_guidelines() {
    return (
        <div>
            <div className='policy_back'></div>
            <div className="policy_main">
                <p><strong>COMMUNITY GUIDELINES</strong></p><p>Welcome to the
                Roja Rose community! This is a safe space to discuss brand/products or values.</p><p>We want
                everyone to be part of our community and have their voice heard.</p><p>We encourage your
                feedback and aim to respond to your comments as soon as possible. While we do moderate this community,
                we welcome open discussion.&nbsp;</p><p>To help everyone enjoy our community, we ask that when
                you post, you keep in mind the following:&nbsp;</p>
                <ul>
                    <li>We don’t allow defamatory, indecent, offensive, profane, discriminatory, misleading,
                        unlawful or threatening comments.&nbsp;</li>
                    <li>Personal attacks, name-calling, trolling and abuse will not be tolerated.</li>
                    <li>Spamming, posting promotional material or posting links to third party websites is not
                        permitted.</li>
                    <li>We reserve the right to delete comments at our discretion and block any repeat offenders. We
                        will remove content that is fraudulent, deceptive or misleading.</li>
                    <li>Coordinated group attacks will not be tolerated.&nbsp;</li>
                    <li>Respect that other people in the community have had different life experiences and may have
                        a different perspective to yours. We welcome different viewpoints.</li>
                    <li>Our community is a public place. Don’t post personal information that you would not be
                        comfortable sharing with a stranger. </li>
                    <li>Employees participating in the discussion in our community are reminded of the staff social
                        media guidelines.</li>
                </ul>
                <p>If you a question about a product or service of ours, please get in touch via our email address at
                    oleksandr.riabykh@gmail.com.</p><p>If you’d like to chat with a customer service representative, please get
                in touch with us at: oleksandr.riabykh@gmail.com.</p>
            </div>
        </div>
    );
}







