import * as React from "react";
import Paper from "@mui/material/Paper";
import {UserList} from "../API/api-requst";

export default function Content() {
    return (
    <Paper sx={{ maxWidth: "auto", margin: "auto", overflow: "hidden" }}>
          <UserList />
    </Paper>
  );
}
