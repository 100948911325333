import * as React from "react";
import Paper from "@mui/material/Paper";
import {MediaPage} from "../API/api-requst";

export default function Media() {
    return (
        <Paper sx={{ maxWidth: "auto", margin: "auto", overflow: "hidden" }}>
            <MediaPage/>
        </Paper>
    );
}
